<template>
  <div class="blog">
    <div class="title">
      <div class="emoji">
        <h1>
          {{ $t('Latest') }} <span style="color: #0041E8;">{{ $t('BlogPosts') }}<img
            src="../../assets/newspaper.svg"
            alt="emoji"
          ></span>
        </h1>
      </div>
      <p>{{ $t('Symphony') }}</p>
    </div>
    <div class="blogs">
      <div
        class="card"
      >
        <a href="/website-redesign-checklist">
          <img
            src="../../assets/redesignblogout.svg"
            class="card-img-top"
            alt="blog picture"
          >
        </a>
        <div class="card-body">
          <a href="/website-redesign-checklist">
            <h5 class="card-title">
              {{ $t('RedesignTitle') }}
            </h5>
          </a>
          <div class="read">
            <div class="auth">
              <img
                src="../../assets/AdrianaAuthor.svg"
                alt="author"
              >
            </div>
            <div class="min">
              <h6>Adriana Rama</h6>
              <p>4 min read</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card"
      >
        <a href="/benefits-of-vuejs">
          <img
            src="../../assets/vueBlogOut.svg"
            class="card-img-top"
            alt="blog picture"
          >
        </a>
        <div class="card-body">
          <a href="/benefits-of-vuejs">
            <h5 class="card-title">
              {{ $t('TheBenefitsVue​') }}
            </h5>
          </a>
          <div class="read">
            <div class="auth">
              <img
                src="../../assets/AdrianaAuthor.svg"
                alt="author"
              >
            </div>
            <div class="min">
              <h6>Adriana Rama</h6>
              <p>7 min read</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card"
      >
        <a href="/saas-platform">
          <img
            src="../../assets/saasout.svg"
            class="card-img-top"
            alt="blog picture"
          >
        </a>
        <div class="card-body">
          <a href="/saas-platform">
            <h5 class="card-title">
              {{ $t('SaasTitle') }}
            </h5>
          </a>
          <div class="read">
            <div class="auth">
              <img
                src="../../assets/AdrianaAuthor.svg"
                alt="author"
              >
            </div>
            <div class="min">
              <h6>Adriana Rama</h6>
              <p>6 min read</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card"
      >
        <a href="/slow-fashion">
          <img
            src="../../assets/fastfashioout.svg"
            class="card-img-top"
            alt="blog picture"
          >
        </a>
        <div class="card-body">
          <a href="/slow-fashion">
            <h5 class="card-title">
              {{ $t('SlowFashionBlogTitle') }}
            </h5>
          </a>
          <div class="read">
            <div class="auth">
              <img
                src="../../assets/AdrianaAuthor.svg"
                alt="author"
              >
            </div>
            <div class="min">
              <h6>Adriana Rama</h6>
              <p>6 min read</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card"
      >
        <a href="/healthcare-digital-dilemma">
          <img
            src="../../assets/cyberOut.svg"
            class="card-img-top"
            alt="blog picture"
          >
        </a>
        <div class="card-body">
          <a href="/healthcare-digital-dilemma">
            <h5 class="card-title">
              {{ $t('CyberBlogTitle') }}
            </h5>
          </a>
          <div class="read">
            <div class="auth">
              <img
                src="../../assets/AdrianaAuthor.svg"
                alt="author"
              >
            </div>
            <div class="min">
              <h6>Adriana Rama</h6>
              <p>3 min read</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card"
      >
        <a href="/from-inventory-mishaps-to-creative-triumphs">
          <img
            src="../../assets/XharraOut.svg"
            class="card-img-top"
            alt="blog picture"
          >
        </a>
        <div class="card-body">
          <a href="/from-inventory-mishaps-to-creative-triumphs">
            <h5 class="card-title">
              {{ $t('InventoryMishapsCreativeTriumphs') }}
            </h5>
          </a>
          <div class="read">
            <div class="auth">
              <img
                src="../../assets/AdrianaAuthor.svg"
                alt="author"
              >
            </div>
            <div class="min">
              <h6>Adriana Rama</h6>
              <p>4 min read</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.blog{
  width: 80%;
  margin: auto;
}
.emoji{
  display: flex;
  text-align: center;
  justify-content: center;
}

.emoji h1{
  font-size: 53px;
  font-weight: 700;
}

.emoji img {
  width: 6%;
  height: 100%;
}

.title{
  text-align: center;
  margin: 8% 0%;
}

.title p{
  color: #061C3D;
  font-size: 18px;
  font-weight: 400;
}

.blogs{
  display: flex;
  justify-content: center;
  gap: 3%;
  margin: auto;
  flex-wrap: wrap;
}

.card{
  border: 1px solid rgb(238, 236, 236);
  border-radius: 6px;
  margin-bottom: 3%;
  width: 22rem;
}

.card-title{
  font-size: 18px;
  margin-bottom: 7%;
}

.read{
  display: flex;
  gap: 3%;
}

.read h6{
  margin-bottom: 0px;
}

.auth{
  width: 15%;
}

.auth img {
  width: 100%;
  height: 100%;
}

.min p{
  margin-bottom: 0px;
}

.min{
  margin-top: 8px;
}
.card-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card a {
  text-decoration: none;
  color: black;
}

.card-img-top{
  width: 100%;
  height: 100%;
}


@media screen and (min-width: 600px) and (max-width: 1000px){
  .blog{
    width: 85%;
    margin-bottom: 5%;
  }

  .blogs{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .emoji h1{
    font-size: 42px;
  }

  .emoji img{
    width: 6%;
  }
}

@media screen and (max-width: 600px){

  .blog{
    width: 90%;
    margin-bottom: 15%;
  }
  .emoji h1{
    font-size: 38px;
  }

  .emoji img{
    width: 10%;
  }

  .title p{
    display: none;
  }
.title{
  display: flex;
  flex-direction: column;
}

.title h1{
  font-size: 30px;
  margin-bottom: 10%;
}

.title button{
  display: none;
}

.card{
  height: fit-content;
  margin-bottom: 10%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1%;
  border: none;
  border-bottom: 1px solid rgb(201, 201, 201);
  border-radius: 0%;
}

.read{
  display: flex;
  /* flex-direction: column; */
  width: 100%;
}

.card-body{
  display: flex;
  flex-direction: column;
  padding: 0%;
  width: 100%;
  padding-left: 4px;
}

.auth{
  width: 45%;
}

.min{
  width: 100%;
}

.latestBlog{
  margin-bottom: 0%;
  background: none;
}

.card-img-top{
  width: 100%;
  height: 95%;
}

.card-title{
  font-size: 16px;
}

.cras p {
  display: none;
}

.min h6{
  font-size: 14px;
}

.min p{
  font-size: 12px;
}
}
</style>