<template>
  <div>
    <ViewCareer />
    <ApplyForm />
  </div>
</template>

<script>
import ViewCareer from "../components/ViewCareer/ViewCareer.vue"
import ApplyForm from "../components/ViewCareer/ApplyForm.vue"

export default {
  components: {
    ViewCareer,
    ApplyForm
  }
}
</script>