<template>
  <div>
    <AboutUs />
    <OurStory />
    <ClientWords />
  </div>
</template>

<script>
import AboutUs from "../components/AboutUs/AboutUs.vue"
import OurStory from "../components/AboutUs/OurStory.vue"
import ClientWords from "../components/AboutUs/ClientWords.vue"

export default {
  components: {
    AboutUs,
    OurStory,
    ClientWords
  }
}
</script>