<template>
  <div class="agile">
    <div class="text">
      <p>{{ $t('WhyChooseUs') }}</p>
      <h1>
        <span style="color: #0041E8;">{{ $t('AgileWork') }}</span>{{ $t('ForBestSolution') }} 
      </h1>
      <p>
        {{ $t('ChooseIntegrity') }} 
      </p>
    </div>
    <div class="timeline">
      <img
        src="../../assets/timeline.svg"
        alt="Work Timeline"
      >
    </div>
  </div>
</template>

<style scoped>
.agile{
  display: flex;
  width: 73%;
  margin: auto;
  margin-bottom: 8%;
  margin-top: 3%;
}

.text p{
  color: #A7ABB6;
  font-size: 16px;
}

.text h1{
  font-size: 44px;
  font-weight: 700;
}

.timeline img{
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1300px) and (max-width: 1600px){
  .timeline img{
    width: 95%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .agile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-bottom: 10%;
  }

  .text{
    margin: auto;
  }

  .timeline{
    margin: auto;
  }

  .view h1{
    font-size: 40px;
  }

  .view button{
    width: 190px;
  }
}

@media screen and (max-width: 600px){

  .agile{
    margin-bottom: 20%;
    width: 85%;
    display: flex;
    flex-direction: column;
  }

  .text p{
    display: flex;
    justify-content: center;
  }
  .text h1{
    font-size: 30px;
    line-height: 42px;
  }
}
</style>