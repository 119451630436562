<template>
  <div>
    <WorkWithUs />
    <ServicesSlider />
    <videoDigit />
    <PartnersSlider />
    <HomeServices />
    <AgileWork />
    <!-- <OurProjects /> -->
    <LatestBlog />
    <HomeContactForm />
  </div>
</template>

<script>
import WorkWithUs from '../components/Home/WorkWithUs.vue'
import ServicesSlider from '../components/Home/ServicesSlider.vue'
import videoDigit from '../components/Home/videoDigit.vue'
import PartnersSlider from '../components/Home/PartnersSlider.vue'
import HomeServices from '../components/Home/HomeServices.vue'
import AgileWork from '../components/Home/AgileWork.vue'
// import OurProjects from '../components/Home/OurProjects.vue'
import LatestBlog from '../components/Home/LatestBlog.vue'
import HomeContactForm from '../components/Home/HomeContactForm.vue'

export default {
  components: {
    WorkWithUs,
    ServicesSlider,
    videoDigit,
    PartnersSlider,
    HomeServices,
    AgileWork,
    // OurProjects,
    LatestBlog,
    HomeContactForm
  }
}
</script>