<template>
  <div>
    <CyberBlogContent />
    <LatestBlogInPageCS />
  </div>
</template>

<script>
import CyberBlogContent from '../components/CyberSecurityBlog/CyberBlogContent.vue'
import LatestBlogInPageCS from '../components/CyberSecurityBlog/LatestBlogInPageCS.vue'

export default {
  components: {
    CyberBlogContent,
    LatestBlogInPageCS,
  }
}
</script>