<template>
  <div>
    <CyberSecurity />
    <IntroductionCyber />
  </div>
</template>

<script>
import CyberSecurity from "@/components/cyberSecurity/CyberSecurity.vue"
import IntroductionCyber from "@/components/cyberSecurity/IntroductionCyber.vue"

export default {
  components: {
    CyberSecurity,
    IntroductionCyber
  }
}
</script>