<template>
  <div style="background: #f3f7ff">
    <div class="footer">
      <footer>
        <div class="cont">
          <div class="logo">
            <a href="/">
              <img
                src="../../assets/Logo.svg"
                alt="DigIT Logo"
              >
            </a>
          </div>

          <div class="links">
            <b>{{ $t('QuickLinks') }}</b>
            <a href="/aboutus"><p>{{ $t("About") }}</p></a>
            <a href="/career"><p>{{ $t("Career") }}</p></a>
            <a href="/contact"><p>{{ $t("ContactUs") }}</p></a>
          </div>


          <!-- <div class="links">
            <b>SERVICES</b>
            <p>UI/UX Design</p>
            <p>Branding</p>
            <p>App Design</p>
          </div> -->

          <div class="links">
            <b>{{ $t("Products") }}</b>
            <a
              href="https://www.spim.app/spim-fashion"
              target="_blank"
            ><p>{{ $t('SPIMFashion') }}</p></a>
            <a
              href="https://www.spim.app/spim-logistics"
              target="_blank"
            ><p>{{ $t('SPIMLogistic') }}</p></a>
          </div>

          <div class="socialMedia">
            <a
              href="https://www.facebook.com/DigITConsultingGmbH"
              target="_blank"
            >
              <img
                src="../../assets/Facebook.svg"
                alt="facebook"
              >
            </a>
            <a
              href="https://ch.linkedin.com/company/digit-business-gmbh"
              target="_blank"
            >
              <img
                src="../../assets/linkedin.svg"
                alt="linkedin"
              >
            </a>
            <a
              href="https://www.instagram.com/digitbusiness/"
              target="_blank"
            >
              <img
                src="../../assets/Instagram.svg"
                alt="instagram"
              >
            </a>
            <a
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FDigIT2122"
              target="_blank"
            >
              <img
                src="../../assets/XLogo.svg"
                alt="twitter"
              >
            </a>
          </div>
        </div>
      </footer>
    </div>
    <hr style="border: 1px solid #d8d8d9; width: 73%; margin: auto; margin-bottom: 1%">
    <div class="copyright">
      <p>© {{ currentYear }} - DigIT</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      currentYear: moment().format('YYYY'),
    };
  },
};
</script>

<style scoped>
.footer {
  background: #f3f7ff;
  height: 400px;
  display: flex;
}
footer {
  display: flex;
  width: 100%;
}

footer p {
  color: #6a778b;
}

.logo {
  width: 20%;
}

.logo img {
  width: 50%;
  height: 30%;
}

.logo img {
  margin-bottom: 7%;
}

.socialMedia {
  display: flex;
  flex-direction: column;
  width: 9%;
}

.socialMedia img {
  width: 20%;
  margin-bottom: 3%;
  height: 100%;
}

.copyright {
  display: flex;
  justify-content: center;
}

.cont {
  display: flex;
  width: 73%;
  margin: auto;
  justify-content: space-between;
}

.links a {
  text-decoration: none;
}

.socialMedia a {
  width: 100%;
}

.socialMedia img {
  width: 100%;
  margin-bottom: 8%;
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .socialMedia {
    display: flex;
    flex-direction: column;
    width: 12%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer {
    height: fit-content;
  }

  .socialMedia {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2%;
    gap: 2%;
    width: 100%;
  }

  .logo {
    width: 30%;
    margin-bottom: 8%;
    padding-top: 8%;
  }

  .logo img {
    width: 100%;
  }

  .links {
    text-align: center;
    margin-bottom: 5%;
  }

  .socialMedia img {
    margin-bottom: 7%;
  }

  .socialMedia a {
    width: 17%;
  }

  .socialMedia img {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .cont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer {
    height: fit-content;
  }

  .logo p {
    display: none;
  }

  .logo {
    width: 50%;
    margin-bottom: 12%;
    padding-top: 20%;
  }

  .logo img {
    width: 100%;
  }

  .links {
    text-align: center;
    margin-bottom: 5%;
  }

  .socialMedia img {
    margin-bottom: 7%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .socialMedia a {
    width: 40%;
  }

  .socialMedia {
    display: flex;

    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 5%;
    width: 100%;
    gap: 5%;
  }
}
</style>
