<template>
  <div class="background">
    <div class="heading">
      <div class="menu">
        <a href="#introduction"><h4 :class="{ active: activeElement === 'introduction' }">{{ $t('Introduction') }}</h4></a>
        <a href="#key"><h4 :class="{ active: activeElement === 'key' }">{{ $t('KeyFeatures') }}</h4></a>
        <a href="#benefits"><h4 :class="{ active: activeElement === 'benefits' }">{{ $t('Benefits') }}</h4></a>
      </div>
      <div class="introduction">
        <h2
          id="introduction"
        >
          {{ $t('Introduction') }}
        </h2>
        <p>
          {{ $t('DigitalIntroduction') }}<br><br>
          {{ $t('FirstdescUI') }}<br><br>
          {{ $t('SeconddescUI') }}<br><br>
          {{ $t('ThirddescUI') }}<br><br>
        </p>
        <img
          src="../../assets/uiuxservice1.svg"
          alt=""
        >
        <h2
          id="key"
          class="key"
        >
          {{ $t('KeyFeatures') }}
        </h2>
        <b>{{ $t('UIUXDesign') }}</b>
        <p>&#x2022; {{ $t('UserResearch') }}</p>
        <p>&#x2022; {{ $t('Wireframing') }}</p>
        <p>&#x2022; {{ $t('Interaction') }}</p>
        <b>{{ $t('Graphic') }}</b>
        <p> &#x2022; {{ $t('Logo') }}</p>
        <p> &#x2022; {{ $t('Colateral') }}</p>
        <p> &#x2022; {{ $t('Media') }}</p>
        <b>{{ $t('AppDesign') }}</b>
        <p> &#x2022; {{ $t('Responsive') }}</p>
        <p> &#x2022; {{ $t('Interface') }}</p>
        <b>{{ $t('Testing') }}</b>
        <p> &#x2022; {{ $t('Refine') }}</p>
        <p> &#x2022; {{ $t('Iterate') }}</p>
        <h2
          id="benefits"
          class="key"
        >
          {{ $t('Benefits') }}
        </h2>
        <p> &#x2022; {{ $t('BrandConsistency') }} </p>
        <p> &#x2022; {{ $t('UserEngagement') }} </p>
        <p> &#x2022; {{ $t('IncreasedConversions') }} </p>
        <p> &#x2022; {{ $t('BrandIdentity') }} </p>
      </div>
    </div>
    <div class="tech">
      <div><h2>{{ $t('OurTechStack') }}</h2></div>
      <div class="stack">
        <img
          src="../../assets/ai.svg"
          alt="ai"
        >
        <img
          src="../../assets/ps.svg"
          alt="ps"
        >
        <img
          src="../../assets/xd.svg"
          alt="xd"
        >
        <img
          src="../../assets/ae.svg"
          alt="ae"
        >
        <img
          src="../../assets/adobe.svg"
          alt="adobe"
        >
        <img
          src="../../assets/figma.svg"
          alt="figma"
        >
      </div>
    </div>
    <div class="respond">
      <h1>{{ $t('WeRespond') }}</h1>
      <a href="/contact"><button id="email">
        {{ $t('SendAnEmail') }}<img
          src="../../assets/arrow-right.svg"
          alt="arrow"
        >
      </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeElement: null
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const introductionElement = document.getElementById('introduction');
      const keyElement = document.getElementById('key');
      const benefitsElement = document.getElementById('benefits');
      const h4Elements = document.querySelectorAll('.menu a h4'); // Adjusted selector

      if (introductionElement && keyElement && benefitsElement && h4Elements) {
        const scrollPosition = window.scrollY;

        if (scrollPosition >= introductionElement.offsetTop && scrollPosition < keyElement.offsetTop) {
          this.activeElement = 'introduction';
        } else if (scrollPosition >= keyElement.offsetTop && scrollPosition < benefitsElement.offsetTop) {
          this.activeElement = 'key';
        } else if (scrollPosition >= benefitsElement.offsetTop) {
          this.activeElement = 'benefits';
        } else {
          this.activeElement = null;
        }

        h4Elements.forEach((h4Element) => {
          const elementId = h4Element.parentElement.getAttribute('href').substring(1); // Adjusted line
          h4Element.classList.toggle('active', this.activeElement === elementId);
        });
      }
    }
  }
};
</script>

<style scoped>

.background{
  width: 73%;
  margin: auto;
  margin-bottom: 4%;
  margin-top: 4%;
}

.heading{
  display: flex;
  width: 80%;
  margin: auto;
  gap: 10%;
}

.menu h4.active {
  color: blue; /* Add this rule to change the color to blue for the active h4 */
}

.menu {
  width: 45%;
  border-top: 1px solid lightgray;
  padding-top: 2%;
  height: 200px;
  position: sticky;
  top: 20px;
}


.introduction{
  width: 100%;
}

.introduction h2{
  font-size: 30px;
  font-weight: 600;
  color: #101828;
}

.key{
  margin: 3% 0%;
}

.introduction h1{
  color: #101828;
  font-size: 30px;
  font-weight: 500;
  margin-top: 5%;
  border-left: 2px solid #6941C6;
  padding-left: 2%;
}

.introduction p{
  font-size: 18px;
  font-weight: 400;
  color:#667085;
}

.introduction b{
  color:#667085 ;
  font-size: 18px;
  font-weight: 700;
}

.introduction img{
  width: 100%;
  height: 30%;
}

.menu h4{
  font-size: 18px;
  font-weight: 600;
  color: #667085;
  margin-bottom: 4%;
}

.menu a{
  text-decoration: none;
}
.respond{
  width: 100%;
  height: 90px;
  background: #0041E8;
  border-radius: 16px;
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 2% 4%;
  align-items: center;
  margin-top: 10%;
}

.respond h1{
  color: white;
  font-size: 32px;
  font-weight: 600;
}

#email{
  width: 212px;
  height: 48px;
  display: flex;
  border-radius: 6px;
  padding: 0px 32px;
  gap: 12px;
  align-items: center;
  background: white;
  font-size: 16px;
  font-weight: 700;
  color: #0B63E5;
  border: none;
}

.respond a{
  text-decoration: none;
}

#email img{
  width: 15%;
  height: 100%;
}

.tech {
  width: 90%;
  margin: auto;
  margin-top: 5%;
}

.stack {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tech h2 {
  display: flex;
  justify-content: center;
}

.stack img {
  width: 10%;
}

@media screen and (min-width: 1400px) and (max-width: 1800px) {
  .introduction img{
    width: 100%;
    height: 24%;
  }

  .tech {
    margin-top: 10%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1400px){

.background{
  width: 90%;
}
.heading {
  width: 85%;
}

.respond{
  width: 90%;
  margin: auto;
}

.tech {
  margin-bottom: 2%;
}

.respond h1{
  color: white;
  font-size: 30px;
  font-weight: 600;
}

#email{
  width: 180px;
  height: 48px;
  display: flex;
  border-radius: 6px;
  padding: 0px 12px;
  gap: 10px;
  align-items: center;
  background: white;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  color: #0B63E5;
  border: none;
}

.introduction img{
  width: 100%;
  height: 22%;
}
}

@media screen and (min-width: 600px) and (max-width: 1100px){
.background{
  width: 100%;
}

.heading {
  width: 90%;
}

.respond{
  width: 90%;
  margin: auto;
  margin-top: 4%;
  margin-bottom: 5%;
}

.respond h1{
  color: white;
  font-size: 20px;
  font-weight: 600;
}

#email{
  width: 180px;
  height: 48px;
  display: flex;
  border-radius: 6px;
  padding: 0px 12px;
  gap: 10px;
  align-items: center;
  background: white;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  color: #0B63E5;
  border: none;
}

.stack img {
  width: 12%;
}

.introduction img{
  width: 100%;
  height: 14%;
}
}

@media screen and (max-width: 600px) {
.background {
  width: 100%;
}

.heading {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.menu {
  display: flex;
  width: 100%;
  margin: auto;
  gap: 5%;
  border: none;
  height: fit-content;
  position: relative;
  top: 0px;
  margin: 5% 0%;
}

.menu h4 {
  font-size: 13px;
}

.tech h2 {
  font-size: 18px;
}
.menu h4.active {
  color: #0041E8;
  background: #DDE6FF;
}

.introduction h2{
  font-size: 18px;
  font-weight: 600;
  color: #101828;
}

.introduction p{
  font-size: 14px;
  font-weight: 400;
  color:#667085;
}

.introduction b{
  color:#667085 ;
  font-size: 13px;
  font-weight: 700;
}

.respond{
  width: 90%;
  margin: auto;
  height: 90px;
  background: #0041E8;
  border-radius: 16px;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 5% 4%;
  align-items: center;
  margin-top: 4%;
}

.respond h1{
  color: white;
  font-size: 14px;
  font-weight: 600;
}

#email{
  width: 133px;
  height: 27px;
  display: flex;
  border-radius: 6px;
  padding: 0px 8px;
  gap: 8px;
  align-items: center;
  background: white;
  font-size: 12px;
  font-weight: 700;
  color: #0B63E5;
  border: none;
}

#email img{
  width: 15%;
}

.stack img {
  width: 21%;
}
}
</style>