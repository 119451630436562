<template>
  <main>
    <div class="about">
      <div class="title">
        <h1>
          <span style="color: #0041e8">{{ $t('ReadyTo') }} </span> {{ $t('DigIT?') }}
          <img
            src="../../assets/world.svg"
            alt="about"
          >
        </h1>
      </div>
      <div class="aboutImage">
        <video
          controls
          width="100%"
          poster="../../assets/videoprev.svg"
        >
          <source
            src="../../assets/video/DIGIT.mp4"
            type="video/mp4"
          >

        </video>
      </div>
      <div class="aboutText">
        <h2>{{ $t('LiveInADream') }}</h2>
        <p>
          {{ $t('OurHistory') }}
        </p>
      </div>
    </div>
  </main>
</template>

<style scoped>
.about {
  width: 73%;
  margin: auto;
  margin-top: 4%;
}

.aboutImage {
  width: 100%;
}

.aboutImage img {
  width: 100%;
}

.title {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}

.title h1 {
  font-size: 56px;
  font-weight: 700;
  color: #061c3d;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1%;
}

.aboutText h2 {
  font-size: 48px;
  font-weight: 600;
  color: #111013;
  margin-bottom: 2%;
  margin-top: 2%;
}

.aboutText p {
  color: #516371;
  font-size: 17px;
  font-weight: 300;
}

.title img {
  width: 4%;
  height: 100%;
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .aboutText h2 {
    font-size: 45px;
    font-weight: 600;
    color: #111013;
    margin-bottom: 2%;
    margin-top: 2%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .about {
    width: 85%;
  }

  .title img {
    width: 7%;
  }

  .title h1 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .about {
    width: 90%;
  }
  .title h1 {
    font-size: 34px;
    width: 100%;
  }

  .title img {
    width: 8%;
  }

  .aboutText h2 {
    font-size: 18px;
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .aboutText p {
    font-size: 16px;
    line-height: 26px;
  }
}
</style>
