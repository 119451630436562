<template>
  <div>
    <OurLocation />
    <ContactUs />
  </div>
</template>

<script>
import OurLocation from "../components/ContactUs/OurLocation.vue"
import ContactUs from "../components/ContactUs/ContactUs.vue"

export default {
  components: {
    OurLocation,
    ContactUs
  }
}
</script>