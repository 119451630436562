<template>
  <div class="location">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25704.479479832873!2d8.486081248220739!3d47.37583468129656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479009e402ed904b%3A0x64f06f7a3f8075c4!2sDigIT%20Business%20GmbH!5e0!3m2!1sen!2s!4v1704371850263!5m2!1sen!2s"
      width="100%"
      height="650"
      style="border:0; margin-top: 3%"
      loading="lazy"
      title="DigITLocation"
      referrerpolicy="no-referrer-when-downgrade"
    >Digital Marketing, DigIT Business, Software Solutions, Saas App, Website, Design, Fashion, Logistic, DigIT Consulting, Automotive Cyber Security, UI/UX, Graphic Design, Data Management</iframe>
    <div class="branches">
      <h3>Office Branches</h3>
      <span>Zürich</span>
      <p>HQ - Steinstrasse 56, 8003 Zürich</p>
      <span>Prishtina</span>
      <p>Branch - R.Mala, 28A Prishtina</p>
    </div>
  </div>
</template>

<style scoped>
.location {
  width: 73%;
  margin: auto;
  height: 693px;
}

.branches {
  /* position: relative;
  top: -420px;
  left: 985px; */
  transform: translate(975px, -433px);
  width: 25%;
  background: white;
  padding: 3%;
  opacity: 94%;
  border-radius: 6px;
}

.branches h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12%;
}

.branches span {
  color: #0041e8;
  font-size: 17px;
  font-weight: 500;
}

.branches p {
  font-size: 17px;
  margin-bottom: 10%;
}

@media screen and (min-width: 1600px) and (max-width: 1770px) {
  .branches {
    transform: translate(780px, -420px);
    width: 30%;
    background: white;
    padding: 3%;
    opacity: 94%;
    border-radius: 6px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1600px) {
  .branches {
    transform: translate(15px, -400px);
    width: 35%;
    background: white;
    padding: 3%;
    opacity: 94%;
    border-radius: 6px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .location {
    width: 100%;
  }

  .branches {
    transform: translate(15px, -400px);
    width: 40%;
    background: white;
    padding: 3%;
    opacity: 94%;
    border-radius: 6px;
  }
}

@media screen and (max-width: 600px) {
  .location {
    width: 100%;
    height: 514px;
  }
  iframe {
    height: 500px;
  }

  .branches {
    transform: translate(10px, -275px);
    width: 65%;
    background: white;
    padding: 3%;
    opacity: 94%;
    border-radius: 6px;
  }
}
</style>
