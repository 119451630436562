<template>
  <div>
    <RedesignBlogContent />
    <LatestBlogInRedesign />
  </div>
</template>

<script>
import RedesignBlogContent from "@/components/redesignBlog/RedesignBlogContent.vue"
import LatestBlogInRedesign from "@/components/redesignBlog/LatestBlogInRedesign.vue"

export default {
  components: {
    RedesignBlogContent,
    LatestBlogInRedesign,
  }
}
</script>