<template>
  <div class="background">
    <div
      class="heading"
      :style="getLanguage == 'ar' ? `display: flex; flex-direction: row-reverse` : `display: flex; flex-direction: row`"
    >
      <div
        class="title"
        :style="getLanguage == 'ar' ? `text-direction: right` : `text-direction: left`"
      >
        <h1>{{ $t('GraphicDesign') }}</h1>
        <p>{{ $t('GraphicUndertext') }}</p>
      </div>
      <div class="arrow">
        <img
          src="../../assets/uiuxservice.svg"
          alt="UI/UX & Digital Marketing"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters ([
      'getLanguage'
    ])
  }
}
</script>

<style scoped>
.background{
  width: 73%;
  margin: auto;
  margin-bottom: 4%;
  margin-top: 4%;
}

.heading{
  display: flex;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* text-align: left; */
  width: 61%;
}

.title h1 {
  color: #0041E8;
  font-size: 60px;
  font-weight: 600;
}

.title p{
  color: #667085;
  font-size: 20px;
  font-weight: 400;
}

.arrow img{
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1000px) and (max-width: 1600px) {
  .title h1 {
  font-size: 45px;
}

}

@media screen and (min-width: 600px) and (max-width: 1100px) {
  .title h1 {
    font-size: 36px;
  }

  .title p {
    font-size: 12px;
  }

  .background{
    width: 90%;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* text-align: left; */
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .title h1 {
    font-size: 18px;
  }

  .title p {
    font-size: 6px;
  }

  .background{
    width: 95%;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* text-align: left; */
    width: 100%;
    position: relative;
    left: 10px;
  }

  .arrow {
    width: 100%;
  }
}

</style>