<template>
  <div>
    <OurCareers />
  </div>
</template>

<script>
import OurCareers from "../components/Career/OurCareers.vue"

export default {

  components: {
    OurCareers,
  }
}
</script>