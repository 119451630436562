<template>
  <div class="AllServices">
    <div class="topNotch">
      <h1>
        {{ $t('Grow') }}  
      </h1>
    </div>
    <div class="services">
      <div
        class="card"
      >
        <a href="/strategic-analysis"><img
          src="../../assets/Icon1.svg"
          class="card-img-top"
          alt="icon"
        >
        </a>
        <div class="card-body">
          <a
            href="/strategic-analysis"
            style="text-decoration: none;"
          ><h5 class="card-title">
            {{ $t('ITStrategicAnalysis') }}
          </h5>
          </a>
          <p class="card-text">
            {{ $t('ITServiceHomeDesc') }}
          </p>
        </div>
      </div>

      <div
        class="card"
      >
        <a href="/business-analysis"><img
          src="../../assets/Icon2.svg"
          class="card-img-top"
          alt="icon"
        >
        </a>
        <div class="card-body">
          <a
            href="/business-analysis"
            style="text-decoration: none;"
          ><h5 class="card-title">
            {{ $t('BusinessAnalysis') }}
          </h5>
          </a>
          <p class="card-text">
            {{ $t('BusinessHomeDesc') }}
          </p>
        </div>
      </div>

      <div
        class="card"
      >
        <a href="/solution-engineering"><img
          src="../../assets/Icon3.svg"
          class="card-img-top"
          alt="icon"
        >
        </a>
        <div class="card-body">
          <a
            href="/solution-engineering"
            style="text-decoration: none;"
          ><h5 class="card-title">
            {{ $t('SolutionsEngineering') }}
          </h5>
          </a>
          <p class="card-text">
            {{ $t('SolutionsHomeDesc') }} 
          </p>
        </div>
      </div>
      <!-- </div> -->
      <!-- <div class="servicesTwo"> -->
      <div
        class="card"
      >
        <a href="uiux-graphic-design"><img
          src="../../assets/Icon4.svg"
          class="card-img-top"
          alt="icon"
        >
        </a>
        <div class="card-body">
          <a
            href="uiux-graphic-design"
            style="text-decoration: none;"
          ><h5 class="card-title">
            {{ $t('GraphicDesign') }}
          </h5>
          </a>
          <p class="card-text">
            {{ $t('GraphicHomeDesc') }}
          </p>
        </div>
      </div>

      <div
        class="card"
      >
        <a href="/data-management"><img
          src="../../assets/Icon6.svg"
          class="card-img-top"
          alt="icon"
        >
        </a>
        <div class="card-body">
          <a
            href="/data-management"
            style="text-decoration: none;"
          ><h5 class="card-title">
            {{ $t('DataManagement') }}
          </h5>
          </a>
          <p class="card-text">
            {{ $t('DataHomeDesc') }}
          </p>
        </div>
      </div>

      <div
        class="card"
      >
        <a href="/cyber-security"><img
          src="../../assets/Icon5.svg"
          class="card-img-top"
          alt="icon"
        >
        </a>
        <div class="card-body">
          <a
            href="/cyber-security"
            style="text-decoration: none;"
          ><h5 class="card-title">
            {{ $t('CyberSecurity') }}
          </h5>
          </a>
          <p class="card-text">
            {{ $t('CyberHomeDesc') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.AllServices{
  width: 73%;
  margin: auto;
}

.topNotch h1, .topNotch p{
  text-align: center;
}

.topNotch h1{
  font-size: 56px;
  font-weight: 700;
  line-height: 60px;
}

.topNotch{
  margin-bottom: 8%;
}

.services{
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 5%;
  margin: auto;
}

.card{
  border: none;
  width: 19rem;
  margin-bottom: 8%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.card-img-top{
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: #061C3D;
}

.card-text{
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

@media screen and (min-width: 1300px) and (max-width: 1600px){
  .services{
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 5%;
  margin: auto;
}

.AllServices{
  width: 90%;
  margin: auto;
}
}

@media screen and (min-width: 1000px) and (max-width: 1300px){
.topNotch h1{
  font-size: 50px;
}
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .topNotch h1{
    font-size: 40px;
    line-height: 50px;
  }

  .AllServices{
    margin-top: 20%;
    padding-top: 15%;
  }
}

@media screen and (min-width: 480px) and (max-width: 600px){
  .AllServices{
    margin-bottom: 20%;
    margin-top: 80% !important;
    width: 90%
  }
}

@media screen and (max-width: 600px){
  .topNotch h1{
    font-size: 28px;
    line-height: 35px;
  }

  .topNotch p{
    font-size: 18px;
    line-height: 27px;
  }

  .AllServices{
    margin-bottom: 20%;
    margin-top: 20%;
    width: 90%
  }

  .card-img-top{
    width: 80%;
  }

  .card-title{
    font-size: 16px;
  }

  .card-text{
    font-size: 13px;
  }
}
</style>