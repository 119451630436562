import { createRouter, createWebHistory } from "vue-router";
import VueRouter from "vue-router";
import HomePage from "../views/HomePage.vue";
import AboutUsPage from "../views/AboutUsPage.vue";
import CareerPage from "../views/CareerPage.vue";
import ViewCareerPage from "../views/ViewCareerPage.vue";
import BlogPage from "../views/BlogPage.vue";
import ContactUsPage from "../views/ContactUsPage.vue";
import XharraBlogPage from "../views/XharraBlogPage.vue";
import CyberSecurityBlogPage from "../views/CyberSecurityBlogPage.vue";
import SlowFashionBlogPage from "../views/SlowFashionBlogPage.vue";
import SaaSBlogPage from "../views/SaaSBlogPage.vue";
import NotFound from "../views/NotFound.vue";
import StrategicAnalysisPage from "../views/StrategicAnalysisPage.vue";
import BusinessAnalysisPage from "../views/BusinessAnalysisPage.vue";
import SolutionsEngineeringPage from "../views/SolutionsEngineeringPage.vue";
import GraphicDesignPage from "../views/GraphicDesignPage.vue";
import DataManagementPage from "../views/DataManagementPage.vue";
import CyberSecurityPage from "../views/CyberSecurityPage.vue";
import VuejsBlogPage from "../views/VuejsBlogPage.vue";
import RedesignBlogPage from "../views/RedesignBlogPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      title: "Empowering Business Growth Through Innovative IT Solutions | DigIT",
      metaTags: [
        {
          name: "description",
          content:
            "Unlock your business's true potential with DigIT. Explore our comprehensive IT services, including strategic analysis, business evaluation, solution engineering, UI/UX design, data management, and automotive cybersecurity. Partnered with industry leaders like Microsoft, AWS, and more. Experience agile work and IT integrity for optimal business growth.",
        },
        {
          property: 'og:description',
          content: 'Discover DigIT - Your partner for comprehensive IT solutions. Maximize your organizational efficiency and unlock your business\'s true potential with our strategic analysis, business evaluation, solution engineering, UI/UX design, data management, and automotive cybersecurity services. Trusted by leading partners like Microsoft, AWS, and more. Grow your business with DigIT\'s agile work and innovative solutions.'
        } 
      ],
    },
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUsPage,
    meta: {
      title: "Empowering Growth: Discover DigIT's Journey in Shaping Business Futures",
      metaTags: [
        {
          name: "description",
          content:
            "Embark on DigIT's journey, founded in 2021, where the dream of business growth meets a commitment to nurturing young talents and fostering innovation. With over 20 talented employees, our mission is to be a catalyst for transformation, leveraging cutting-edge technology to drive efficiency, creativity, and growth. Join us in shaping a future where technology integrates seamlessly into every aspect of life, creating limitless possibilities and positive change. Explore our relentless pursuit of progress, dedication to professional development, and the values that define DigIT.",
        },
        {
          property: 'og:description',
          content: 'Embark on DigIT\'s journey, founded in 2021, where the dream of business growth meets a commitment to nurturing young talents and fostering innovation. With over 20 talented employees, our mission is to be a catalyst for transformation, leveraging cutting-edge technology to drive efficiency, creativity, and growth. Join us in shaping a future where technology integrates seamlessly into every aspect of life, creating limitless possibilities and positive change. Explore our relentless pursuit of progress, dedication to professional development, and the values that define DigIT.'
        } 
      ],
    },
  },
  {
    path: "/career",
    name: "Career",
    component: CareerPage,
    meta: {
      title: "Join Our Team: Explore Career Opportunities at Our Company",
      metaTags: [
        {
          name: "description",
          content: "Discover exciting career opportunities at our company. Whether you're a frontend developer, backend developer, or interested in cybersecurity, we have intern and full-time positions available. Join a dynamic team dedicated to creating innovative solutions and shaping the future of technology."
        },
        {
          property: "og:description",
          content: "Embark on an exciting career journey with us. Explore opportunities for frontend developers, backend developers, and cybersecurity enthusiasts. Whether you're seeking an internship or full-time position, join a team committed to innovation and excellence. Let's create a brighter future together."
        }
      ]
    }
  },
  {
    path: "/opening",
    name: "Opening",
    component: ViewCareerPage,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogPage,
    meta: {
      title: "Explore Our Latest Blog Posts for Insights and Inspiration",
      metaTags: [
        {
          name: "description",
          content: "Dive into our latest blog posts for a symphony of insights, wisdom, and inspiration. From Vue.js benefits to SaaS introductions and discussions on slow fashion and healthcare's digital dilemma, find articles that ignite curiosity and spark innovation."
        },
        {
          property: "og:description",
          content: "Embark on a journey of discovery with our latest blog posts, offering insights and inspiration across a range of topics. Whether you're interested in Vue.js benefits, SaaS platforms, sustainability in fashion, or healthcare data security, our articles are crafted to inform, engage, and inspire."
        }
      ]
    }
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactUsPage,
  },
  {
    path: "/from-inventory-mishaps-to-creative-triumphs",
    name: "xharrablog",
    component: XharraBlogPage,
    meta: {
      title: "From Inventory Mishaps to Creative Triumphs: The SPIM Fashion Story | DigIT Blog",
      metaTags: [
        {
          name: "description",
          content:
            "Discover the inspiring journey from inventory challenges to creative success with SPIM Fashion. Join us as we share the transformative story of collaboration, innovation, and the impact of technology on the fashion industry.",
        },
        {
          property: 'og:description',
          content: 'At DigIT, witness the birth of SPIM Fashion – a revolutionary solution that simplifies material tracking, enhances inventory management, and unlocks creative possibilities for fashion designers. Follow the story of how SPIM became a partner in innovation, creativity, and sustainable fashion practices for renowned designer Drenusha Xharra.'
        } 
      ],
    },
  },
  {
    path: "/healthcare-digital-dilemma",
    name: "cyberblog",
    component: CyberSecurityBlogPage,
    meta: {
      title: "Healthcare's Digital Dilemma: Navigating Data Security Challenges | DigIT Blog",
      metaTags: [
        {
          name: "description",
          content:
            "Explore the critical research on data security in healthcare, focusing on developing countries like Kosovo. Uncover the challenges, risks, and solutions presented by rapid digitization in the health sector. Join the conversation about safeguarding sensitive health data and the imperative for collaborative efforts in the face of cybersecurity threats.",
        },
        {
          property: 'og:description',
          content: 'Dive into the groundbreaking research by Arbenita Bequku, a Cyber Security Automotive Engineer at DigIT, addressing data security challenges in healthcare. The article sheds light on the complexities faced by developing countries, offering insights, solutions, and a call to action for a secure healthcare environment. Stay informed and empowered with our ongoing dialogue on technology, healthcare security, and global insights.'
        } 
      ],
    },
  },
  {
    path: "/slow-fashion",
    name: "slowfashion",
    component: SlowFashionBlogPage,
    meta: {
      title: "Slow Fashion: Navigating Fast Fashion's Environmental and Social Impact | DigIT Blog",
      metaTags: [
        {
          name: "description",
          content:
            "Explore the environmental and social impact of fast fashion and the compelling alternative offered by slow fashion. Delve into the challenges of water pollution, excessive waste, and exploitative labor practices. Learn how slow fashion promotes sustainability, ethical production, and mindful consumption. Discover actionable steps for individuals and businesses to embrace a greener future through conscious fashion choices.",
        },
        {
          property: 'og:description',
          content: 'Discover the transformative journey from fast fashion\'s environmental toll to the sustainable embrace of slow fashion. Uncover the complexities of water pollution, excessive waste, and exploitative labor practices in the fashion industry. Join the movement towards eco-friendly materials, ethical production, and mindful consumption. Explore actionable steps for individuals and businesses to contribute to a greener, more sustainable future in fashion.'
        } 
      ],
    },
  },
  {
    path: "/saas-platform",
    name: "saasplatform",
    component: SaaSBlogPage,
    meta: {
      title: "Unlocking Growth: A Deep Dive into Software as a Service (SaaS) | DigIT Blog",
      metaTags: [
        {
          name: "description",
          content:
            "Explore the transformative power of Software as a Service (SaaS) in the digital era. Discover the definition, history, key characteristics, and benefits of SaaS. Learn how SaaS fosters accessibility, collaboration, and automatic updates, and why businesses are choosing SPIM as a prime SaaS solution. Join us on a journey of financial prudence, collaboration, and sustained growth in the dynamic landscape of digital transformation.",
        },
        {
          property: 'og:description',
          content: 'Dive into the world of Software as a Service (SaaS) with our comprehensive blog. Understand the roots of SaaS, its key characteristics, and the myriad benefits it offers, including cost savings, accessibility, and enhanced data security. Explore the adaptable and expansive nature of SaaS solutions, and discover why SPIM stands out as a strategic partner for businesses seeking uninterrupted growth. Choose the best for the best – embark on a pathway marked by financial prudence, collaboration, and automated productivity with SPIM.'
        } 
      ],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/strategic-analysis",
    name: "StrategicAnalysis",
    component: StrategicAnalysisPage,
    meta: {
      title: "Unlock Digital Potential: IT Strategic Analysis for Enhanced Efficiency | DigIT Services",
      metaTags: [
        {
          name: "description",
          content:
            "Explore our IT Strategic Analysis service designed to unlock your digital potential. Gain comprehensive insights into your IT landscape, optimize systems for performance and scalability, and proactively safeguard your business against cyber threats. Our key features include a thorough IT assessment, SWOT analysis, technology roadmap, and cost-benefit analysis. Experience enhanced efficiency, risk mitigation, and informed decision-making with our strategic IT services.",
        },
        {
          property: 'og:description',
          content: 'Discover the power of IT Strategic Analysis at DigIT Services. Elevate your IT strategy with a comprehensive assessment, SWOT analysis, and a tailored technology roadmap. Our service ensures enhanced efficiency, risk mitigation, and informed decision-making. Unlock your digital potential and pave the way for transformative success in the fast-paced world of technology.'
        } 
      ],
    },
  },
  {
    path: "/business-analysis",
    name: "BusinessAnalysis",
    component: BusinessAnalysisPage,
    meta: {
      title: "Empower Your Enterprise: Comprehensive Business Analysis Services | DigIT Services",
      metaTags: [
        {
          name: "description",
          content:
            "Transform your business with our Business Analysis services, providing a deep dive into your organization's intricacies. Gain insights through process mapping, stakeholder engagement, and SWOT analysis. Our services enable data-driven decision-making, identify areas for improvement, and ensure strategic alignment. Experience enhanced efficiency, informed decision-making, and elevated customer satisfaction with our tailored business analysis solutions.",
        },
        {
          property: 'og:description',
          content: 'Elevate your enterprise with DigIT Solutions\' Business Analysis services. Uncover the intricacies of your business processes through efficient mapping, stakeholder engagement, and detailed SWOT analysis. Our tailored services ensure strategic alignment, informed decision-making, and enhanced customer satisfaction. Empower your organization with comprehensive insights and data-driven decisions in the dynamic landscape of modern business.'
        } 
      ],
    },
  },
  {
    path: "/solution-engineering",
    name: "solutionEngineering",
    component: SolutionsEngineeringPage,
    meta: {
      title: "Innovate, Integrate, and Excel: Solutions Engineering Expertise | DigIT Services",
      metaTags: [
        {
          name: "description",
          content:
            "Unlock the power of innovation with our Solutions Engineering services. Our team of experienced engineers specializes in providing tailored solutions to address your unique business needs. From system integration and process optimization to software development and infrastructure design, we engineer bespoke solutions for seamless operations. Leverage our expertise in data analytics and business intelligence, staying ahead with cutting-edge technologies like JavaScript, Vue.js, React.js, Angular, HTML5, CSS3, and C#. Collaborate with us to create optimal, future-proof solutions for your organization.",
        },
        {
          property: 'og:description',
          content: 'Elevate your organization with DigIT Solutions\' Solutions Engineering expertise. Our experienced engineers are dedicated to providing tailored solutions, addressing your technical challenges, process inefficiencies, and operational optimizations. From system integration to software development and data analytics, our services are designed to propel your organization forward. Collaborate with us to innovate, integrate, and excel in the dynamic landscape of modern business.'
        } 
      ],
    },
  },
  {
    path: "/uiux-graphic-design",
    name: "graphicDesign",
    component: GraphicDesignPage,
    meta: {
      title: "Breathe Life into Your Brand with UI/UX & Graphic Design | DigIT Creative Services",
      metaTags: [
        {
          name: "description",
          content:
            "Elevate your brand's digital presence with DigIT's UI/UX & Graphic Design services. Our skilled designers prioritize user experience, crafting intuitive interfaces and compelling visuals that leave a lasting impact. From user research and wireframing to logo creation and marketing collateral design, we blend creativity with functionality. Stay ahead with designs tailored to industry trends. Benefit from responsive web and mobile app designs, user testing, and a tech stack featuring AI, Photoshop, XD, After Effects, Adobe, and Figma.",
        },
        {
          property: 'og:description',
          content: 'Revitalize your brand\'s online presence with DigIT\'s UI/UX & Graphic Design services. Our expert designers focus on user experience, delivering pixel-perfect interfaces and stunning graphics. From user research to logo creation, our designs prioritize brand consistency, user engagement, and increased conversions. Explore our tech stack, featuring AI, Photoshop, XD, After Effects, Adobe Suite, and Figma. Breathe life into your brand with DigIT Creative Services.'
        } 
      ],
    },
  },
  {
    path: "/data-management",
    name: "dataManagement",
    component: DataManagementPage,
    meta: {
      title: "Empower Your Business with Effective Data Management | DigIT Data Services",
      metaTags: [
        {
          name: "description",
          content:
            "Securely manage and host your data with DigIT Data Management services. In the age of big data, our comprehensive solutions ensure confidential storage, strategic management, and optimal outcomes. Choose from reliable hosting options, implement robust backup strategies, and seamlessly migrate data between systems. Optimize database performance with our tech stack featuring MySQL, AWS, and Azure. Experience reliability, accessibility, and peace of mind with DigIT's Data Management expertise.",
        },
        {
          property: 'og:description',
          content: 'Empower your business with DigIT\'s Data Management services. Strategically manage and securely host your data for informed decision-making. Our comprehensive solutions include secure hosting, robust backup strategies, seamless data migration, and optimized database management. Choose reliability and peace of mind with DigIT\'s tech stack featuring MySQL, AWS, and Azure. Experience the benefits of effective data management with DigIT Data Services.'
        } 
      ],
    },
  },
  {
    path: "/cyber-security",
    name: "cyberSecurity",
    component: CyberSecurityPage,
    meta: {
      title: "Drive with Confidence: Automotive Cyber Security Solutions | Secure Your Vehicles with Cutting-Edge Technology",
      metaTags: [
        {
          name: "description",
          content:
            "Safeguard your vehicles against cyber threats with our Automotive Cyber Security solutions. Our specialized engineering solutions ensure the seamless integration of cutting-edge technology, securing in-vehicle networks and connected systems. Conduct threat analysis, prioritize risks, and implement robust cybersecurity frameworks. Benefit from safety assurance, regulatory compliance, enhanced consumer trust, and future-proofing. Drive into the future with confidence and security.",
        },
        {
          property: 'og:description',
          content: 'Drive with confidence into the future with our Automotive Cyber Security solutions. Specialized engineering fortifies your vehicles against cyber threats, securing in-vehicle networks and connected systems. Our solutions adapt to evolving risks, ensuring ongoing protection. Conduct threat analysis, prioritize risks, and implement robust cybersecurity frameworks. Enjoy safety assurance, regulatory compliance, enhanced consumer trust, and future-proofing with our cutting-edge technology.'
        } 
      ],
    },
  },
  {
    path: "/benefits-of-vuejs",
    name: "VuejsBlog",
    component: VuejsBlogPage,
    meta: {
      title: "The Benefits of Vue.js: A Comprehensive Overview",
      metaTags: [
        {
          name: "description",
          content: "Discover the numerous advantages of Vue.js, a progressive JavaScript framework known for its simplicity, flexibility, and efficiency. From ease of integration to powerful reactivity, explore how Vue.js empowers developers to build modern and scalable web applications."
        },
        {
          property: "og:description",
          content: "Unlock the potential of Vue.js with our comprehensive guide to its benefits. From ease of integration to powerful reactivity, learn how Vue.js stands out as a top choice for building modern web applications. Whether you're a beginner or an experienced developer, Vue.js offers the tools and flexibility to streamline your development workflow and deliver exceptional user experiences."
        }
      ]
    }
  },
  {
    path: "/website-redesign-checklist",
    name: "RedesignBlog",
    component: RedesignBlogPage,
    meta: {
      title: "The Ultimate Website Redesign Checklist",
      metaTags: [
        {
          name: "description",
          content: "Considering a website redesign? Enhance user experience, boost conversions, and stay ahead of the curve with our ultimate website redesign checklist. Follow these steps for a seamless transformation."
        },
        {
          property: "og:description",
          content: "Embark on a website redesign journey with confidence using our comprehensive checklist. From defining goals to post-launch optimization, ensure a seamless transformation of your online presence. Let's revitalize your website and achieve fantastic results together!"
        }
      ]
    }
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
