<template>
  <div>
    <SaasBlogContent />
    <LatestBlogInSaas />
  </div>
</template>

<script>
import SaasBlogContent from "@/components/saasBlog/SaasBlogContent.vue"
import LatestBlogInSaas from "@/components/saasBlog/LatestBlogInSaas.vue"

export default {
  components: {
    SaasBlogContent,
    LatestBlogInSaas,
  }
}
</script>