const AWSurl = {};

if (process.env.VUE_APP_accessKeyId == null) {
  AWSurl.accessKeyId = 'AKIAZN2PMAGNPVDT4HU2';
  AWSurl.secretAccessKey = '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';
  AWSurl.region = 'eu-west-3';

} else {
  AWSurl.accessKeyId = process.env.VUE_APP_accessKeyId;
  AWSurl.secretAccessKey = process.env.VUE_APP_secretAccessKey;
  AWSurl.region = process.env.VUE_APP_region;
}

module.exports = {
  AWSurl,
};
