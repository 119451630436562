<template>
  <div class="blog">
    <img
      src="../../assets/vueBlogIn.svg"
      alt="blog image"
    >
    <div class="inspo">
      <div class="read">
        <div class="author">
          <img
            src="../../assets/AdrianaAuthor.svg"
            alt="author"
          >
        </div>
        <div class="authorname">
          <h6>Adriana Rama</h6>
          <p>February 13, 2024</p>
        </div>
      </div>
      <div
        class="buttons"
        :style="getLanguage == 'ar' ? `display: none` : ``"
      >
        <button>{{ $t('Blog') }}</button>
        <button>{{ $t('Code') }}</button>
        <button>{{ $t('Web') }}</button>
      </div>
    </div>
    <div class="content">
      <h1>
        {{ $t('TheBenefitsVue​') }}
      </h1>
      <p>
        {{ $t('VueIntro') }}
      </p>
      <h2>1. {{ $t('EaseIntegration') }}</h2>
      <p>{{ $t('FirstPoint') }} </p>
      <h2>2. {{ $t('LightweightNature') }} </h2>
      <p>{{ $t('SecondPoint') }} </p>
      <h2>3. {{ $t('VersatilityApplication') }}</h2>
      <p>{{ $t('ThirdPoint') }} </p>
      <h2>4. {{ $t('DeclarativeRendering') }} </h2>
      <p>{{ $t('ForthPoint') }} </p>
      <h2>5. {{ $t('ReactivityUnleashed') }}</h2>
      <p>{{ $t('FifthPoint') }} </p>
      <h2>6. {{ $t('ComponentBasedArchitecture') }}</h2>
      <p>{{ $t('SixthPoint') }} </p>
      <h2>7. {{ $t('VueCLI') }}</h2>
      <p>{{ $t('SeventhPoint') }} </p>
      <h2>8. {{ $t('VueDevtools') }}</h2>
      <p>{{ $t('EightPoint') }}  </p>
      <h2>9. {{ $t('TwoWayDataBinding') }}</h2>
      <p>{{ $t('NinthPoint') }} </p>
      <h2>10. {{ $t('TransitionAnimationCapabilities') }}</h2>  
      <p>{{ $t('TenthPoint') }} </p>
      <h2>11. {{ $t('ActiveSupportiveCommunity') }}</h2>
      <p>{{ $t('EleventhPoint') }} </p>
      <h2>12. {{ $t('TailoringVuejs') }}</h2>
      <p>
        {{ $t('LastPoint') }} 

        {{ $t('LastPointTwo') }}
      </p>
    </div>
    <!-- <div class="interaction">
      <div class="intImg">
        <img
          src="../../assets/AdrianaAuthor.svg"
          alt="author"
        >
      </div>
      <div class="enthusiast">
        <b>Adriana Rama</b>
        <p id="gray">
          Content Writer
        </p>
        <p id="quality">
          Digital content wrangler | UX enthusiast | Recovering educator | Shameless nerd & GIF connoisseur | Hockey fan | Oxford comma or death.
        </p>
      </div>
      <div>
        <img
          src="../../assets/linkedin-with-circle.svg"
          alt="linkedin"
        >
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
    computed: {
    ...mapGetters(["getLanguage"]),
    }
  }

</script>

<style scoped>
.blog{
  width: 73%;
  margin: auto;
  margin-bottom: 4%;
}

.blog img{
  margin-top: 4%;
  width: 100%;
}

.inspo{
  display: flex;
  justify-content: space-between;
  margin: 3% 0%;
  align-items: center;
}

.read{
  display: flex;
  align-items: center;
  width: 30%;
}

.authorname h6{
  font-size: 22px;
  font-weight: 500;
  color: #061C3D;
}

.authorname p{
  font-size: 20px;
  font-weight: 400;
  color: #42526B;
}

.authorname{
  margin-left: 2%;
}

.buttons{
  display: flex;
  gap: 3%;
}

.buttons button{
  background: #F3F7FF;
  width: 100px;
  height: 30px;
  border-radius: 6px;
  border: none;
  color: #161519;
  font-size: 16px;
  font-weight: 500;
  cursor: auto;
}

.content h1{
  font-size: 36px;
  font-weight: 700;
  color: #061C3D;
  margin-bottom: 2%;
}

.content p{
  font-size: 17px;
  font-weight: 300;
  color: #516371;
}

.content{
  margin-bottom: 5%;
  text-align: justify;
  text-justify: inter-word;
}

.interaction{
  display: flex;
  background: #F3F7FF;
  height: 188px;
  border-radius: 8px;
  align-items: center;
  padding: 3%;
  /* justify-content: space-evenly; */
}

.intImg{
  margin-right: 2%;
}

.enthusiast b{
  font-size: 16px;
  font-weight: 700;
  color: #111013;
}

#gray{
  font-size: 15.6px;
  font-weight: 400;
  color: #60606B;
}

.quality{
  font-size: 16.4px;
  font-weight: 400;
  color: #161519;
}

.author{
  width: 23%;
}

.intImg{
  width: 8%;
}

.content h2 {
  font-size: 24px;
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .author{
    width: 13% !important;
  }

  .author img{
    width: 100%;
  }

  .inspo{
    display: flex;
    flex-direction: column;
    margin: 1% 0% !important;
  }

  .read{
    width: 100%;
    margin-bottom: 1% !important;
  }

  .buttons{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

@media screen and (max-width: 1000px){

  .blog{
  width: 85%;
  margin: auto;
  margin-bottom: 4%;
}
  .inspo{
    display: flex;
    flex-direction: column;
    margin: 5% 0%;
  }

  .author img{
    width: 100%;
  }

  .read{
    width: 100%;
    margin-bottom: 5%;
  }
  .author{
    width: 20%;
  }
  .authorname h6{
    font-size: 18px;
    margin-bottom: 0px;
  }

.authorname p{
  font-size: 15px;
  margin-bottom: 0px;
}

.content h1{
  font-size: 25px;
  margin: 5% 0%;
}

.content h2 {
  font-size: 20px;
}
}
</style>