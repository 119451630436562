<template>
  <div class="apply">
    <div class="form">
      <div class="nameEmail">
        <div class="name">
          <label
            class=" label"
            for="full-name"
          >Name</label>
          <input 
            id="full-name"
            v-model="firstName"
            type="text"
            placeholder="Full name"
            required
          >
        </div>
        <div class="emailIn">
          <label
            class=" label"
            for="email"
          >Email</label>
          <input
            id="email"
            v-model="email"
            type="text"
            placeholder="Email adress"
            required
          >
        </div>
      </div>
      <label
        class=" label"
        for="subject"
      >Subjects</label>
      <textarea
        id="subject"
        v-model="subject"
        type="text"
        placeholder="Subject"
        required
      />
      <div class="submit">
        <button @click="submitForm">
          <img
            src="../../assets/arrow-rightW.svg"
            alt="arrow"
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { AWSurl } from "@/domainConfig";
import AWS from "aws-sdk";

export default {
  data() {
    return {
      firstName: "",
      email: "",
      subject: "",
    }
  },
  methods: {
    async submitForm() {
      const ses = new AWS.SES({
        accessKeyId: AWSurl.accessKeyId,
        secretAccessKey: AWSurl.secretAccessKey,
        region: AWSurl.region,
      });

      const params = {
        Destination: {
          ToAddresses: ["hr@digitbusiness.ch"],
        },
        Message: {
          Body: {
            Text: {
              Data: `FullName: ${this.firstName}
              Email: ${this.email}
              Subject: ${this.subject}`,
            },
          },
          Subject: {
            Data: "Form submission from DigIT website career",
          },
        },
        Source: "hr@digitbusiness.ch",
      };

      try {
        await ses.sendEmail(params).promise();
        console.log("Email sent successfully");
      } catch (err) {
        console.error(err);
      }
      this.resetForm();
      },
      resetForm() {
        (this.firstName = ""),
        (this.email = ""),
        (this.subject = "");
      },
    },
  };
</script>

<style scoped>
.apply{
  width: 73%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}

.form{
  width: 50%;
  display: flex;
  flex-direction: column;
}

.nameEmail{
  display: flex;
  justify-content: space-between;
}

.form input, .form textarea{
  border: 1px solid #E6E8EC;
  background: white;
  height: 48px;
  border-radius: 6px;
  margin-bottom: 3%;
  padding: 2%;
}

.form textarea{
  height: 100px;
  padding-top: 1%;
}

.name, .emailIn{
  display: flex;
  flex-direction: column;
  width: 48%;
}

.submit button{
  border: none;
  background:#0041E8;
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.submit{
  display: flex;
  justify-content: flex-end;
}

.label{
  color: #061C3D;
  font-size: 15px;
  font-weight: 400;
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .apply{
    width: 100%;
  }

  .form{
    width: 90%;
  }
}

@media screen and (max-width: 600px){
  .apply{
    width: 100%;
    margin-bottom: 15%;
  }

  .form{
    width: 85%;
  }

  .nameEmail{
    display: flex;
    flex-direction: column;
  }

  .name, .emailIn{
    display: flex;
    width: 100%;
  }
}

</style>