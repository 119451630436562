<template>
  <div>
    <XharraBlogContent />
    <LatestBlogInPage />
  </div>
</template>

<script>
import XharraBlogContent from "../components/XharraBlog/XharraBlogContent.vue"
import LatestBlogInPage from "../components/XharraBlog/LatestBlogInPage.vue"

export default {
  components: {
    XharraBlogContent,
    LatestBlogInPage
  }
}
</script>