<template>
  <div>
    <BusinessAnalysis />
    <IntroductionBusiness />
  </div>
</template>

<script>
import BusinessAnalysis from "@/components/businessAnalysis/BusinessAnalysis.vue"
import IntroductionBusiness from "@/components/businessAnalysis/IntroductionBusiness.vue"

export default {
  components: {
    BusinessAnalysis,
    IntroductionBusiness
  }
}
</script>