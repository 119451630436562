<template>
  <div class="slider">
    <vue-marquee-slider
      id="marquee-slider-text"
      :space="30"
      :speed="25000"
      :width="200"
    >
      <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('MobileDesign') }}</span>
      </div>

      <!-- <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('UI/UX') }}</span>
      </div> -->

      <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('Prototype') }}</span>
      </div>

      <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('WebDesign') }}</span>
      </div>

      <!-- <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>Landing Page</span>
      </div> -->

      <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('Development') }}</span>
      </div>

      <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('BusinessAnalysis') }}</span>
      </div>

      <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('CyberSecuritySlider') }}</span>
      </div>

      <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('DataManagement') }}</span>
      </div>

      <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('ITSupportSlider') }}</span>
      </div>

      <div class="box">
        <img
          src="../../assets/dot.svg"
          alt="icon"
        >
        <span>{{ $t('DigitalMarketingSlider') }}</span>
      </div>
    </vue-marquee-slider>
  </div>
</template>

<script>
import { VueMarqueeSlider } from 'vue3-marquee-slider';

export default {
  components: {
    VueMarqueeSlider
  }
}
</script>

<style scoped>
.box{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.box img{
  display: flex;
  align-items: center;
  width: 4%;
  height: 100%;
}

.box span{
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 300;
  color: #516371;
}

.slider{
  margin-bottom: 5%;
}
</style>