<template>
  <div class="story">
    <div class="scopeTitle">
      <div class="emoji">
        <h3>{{ $t('Mission') }}</h3>
        <img
          src="../../assets/pin.svg"
          alt="pin"
        >
      </div>
      <p>
        {{ $t('OurMission') }}
      </p>
    </div>
    <div class="scope">
      <img
        src="../../assets/mission1.svg"
        alt="Our Mission"
      >
      <img
        src="../../assets/mission2.svg"
        alt="Our Mission"
      >
    </div>
    <div class="scopeTitle">
      <div class="emoji">
        <h3>{{ $t('Vision') }}</h3>
        <img
          src="../../assets/cityscapeatdusk.svg"
          alt="emoji"
        >
      </div>
      <p>
        {{ $t('OurVision') }}
      </p>
    </div>
    <div class="scope">
      <img
        src="../../assets/vision1.svg"
        alt="Our Vision"
      >
      <img
        src="../../assets/vision2.svg"
        alt="Our Vision"
      >
    </div>
  </div>
</template>

<style scoped>
.story {
  width: 73%;
  margin: auto;
  margin-bottom: 8%;
}

.scope {
  display: flex;
  justify-content: space-between;
  width: 50%;
  gap: 2%;
}

.scope img {
  width: 100%;
  height: 100%;
}

.emoji {
  display: flex;
  align-items: center;
}

.emoji img {
  width: 2%;
  height: 100%;
}

.scopeTitle h3 {
  margin: 2% 0%;
  color: #111013;
  font-size: 36px;
  font-weight: 600;
}

.scopeTitle p {
  color: #516371;
  font-size: 17px;
  font-weight: 300;
}

/* @media screen and (min-width: 1000px) and (max-width: 1400px){
  .scope{
    width: 50%;
    gap: 2%;
  }

  .scope img{
    width: 100%;
  }
} */

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .story {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .story {
    width: 90%;
  }
  .emoji img {
    width: 5%;
    margin-left: 1%;
  }

  .emoji h3 {
    font-size: 20px;
  }

  .scopeTitle p {
    font-size: 17px;
    line-height: 30px;
  }

  .scope {
    display: flex;
    flex-direction: column;
    width: 90%;
  }

  .scope img {
    width: 100%;
    margin-bottom: 5%;
  }
}
</style>
