<template>
  <div class="opening">
    <div class="title">
      <div>
        <h1>Social Media - Manager </h1>
      </div>
      <div class="button">
        <button id="clock">
          <img
            src="../../assets/clock.svg"
            alt="clock"
          ><b>Full-Time</b>
        </button>
        <button id="clock">
          <img
            src="../../assets/location.svg"
            alt="location"
          ><b>On-Site</b>
        </button>
      </div>
    </div>
    <div class="description">
      <em>Intern Level</em>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Molestie a iaculis at erat. Viverra mauris in aliquam sem fringilla ut morbi. Sit amet venenatis urna cursus. Adipiscing bibendum est ultricies integer quis auctor. Magna etiam tempor orci eu lobortis elementum nibh tellus. Ac odio tempor orci dapibus ultrices in. Nibh tellus molestie nunc non blandit massa enim nec dui. Faucibus interdum posuere lorem ipsum dolor sit amet consectetur. Sodales neque sodales ut etiam sit amet nisl. Neque volutpat ac tincidunt vitae semper quis. Eget felis eget nunc lobortis mattis aliquam faucibus purus in. Et leo duis ut diam quam nulla. Ut <br><br>

        tellus elementum sagittis vitae et leo duis ut diam. Urna porttitor rhoncus dolor purus non enim praesent elementum facilisis. Aliquam ut porttitor leo a diam sollicitudin tempor id eu. Sapien pellentesque habitant morbi tristique senectus. Ipsum dolor sit amet consectetur adipiscing elit pellentesque. Sit amet mattis vulputate enim nulla. Aliquet porttitor<br> <br>

        A erat nam at lectus urna duis. In vitae turpis massa sed elementum tempus egestas sed sed. Enim praesent elementum facilisis leo vel fringilla est ullamcorper. Egestas erat imperdiet sed euismod nisi porta lorem mollis aliquam. Ornare arcu dui vivamus arcu felis bibendum ut. Ut faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Ac tincidunt vitae semper quis. Sit amet porttitor eget dolor. Purus sit amet volutpat consequat mauris. Ullamcorper eget nulla facilisi etiam dignissim. Risus commodo<br><br>

        viverra maecenas accumsan lacus. Elementum nibh tellus molestie nunc non blandit massa. Amet mauris commodo quis imperdiet massa tincidunt nunc. Id aliquet lectus proin nibh. Sed sed risus pretium quam vulputate dignissim suspendisse in est. Enim ut tellus elementum sagittis vitae. Aliquam vestibulum morbi blandit cursus risus.
      </p>
    </div>
    <hr style="margin-top: 5%">
  </div>
</template>

<style scoped>
.opening{
  width: 73%;
  margin: auto;
  margin-bottom: 3%;
}

.button{
  display: flex;
  gap: 3%;
  margin-bottom: 5%;
  /* align-items: center; */
}

#clock{
  background: #F3F7FF;
  border: none;
  width: 112px;
  height: 38px;
  border-radius: 6px;
  cursor: default;
}

#button b{
  font-size: 14px;
  font-weight: 500;
  color: #1A1A1A;
}

.title{
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  /* align-items: center; */
}

.title h1{
  font-size: 36px;
  font-weight: 600;
  color: #061C3D;
}

.description em{
  font-size: 24px;
  font-weight: 300;
  color: #061C3D;
  margin-bottom: 2%;
}

.description p{
  font-size: 16px;
  font-weight: 300;
  color: #516371;
  margin-top: 1%;
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .opening{
    width: 90%;
  }

  .apply{
    width: 90%;
  }

  .form{
    width: 100%;
  }
}

@media screen and (max-width: 600px){
  .opening{
    width: 85%;
    margin: auto;
    margin-bottom: 3%;
  }

  .title{
    display: flex;
    flex-direction: column;
    margin-top: 5%;
  }

  .title h1{
    font-size: 28px;
  }

  .description em{
    font-size: 19px;
  }

  .description p{
    font-size: 16px;
  }
}
</style>