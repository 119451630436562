<template>
  <div class="blog">
    <img
      src="../../assets/SaaSInside.svg"
      alt="blog image"
    >
    <div class="inspo">
      <div class="read">
        <div class="author">
          <img
            src="../../assets/AdrianaAuthor.svg"
            alt="author"
          >
        </div>
        <div class="authorname">
          <h6>Adriana Rama</h6>
          <p>December 14, 2023</p>
        </div>
      </div>
      <div
        class="buttons"
        :style="getLanguage == 'ar' ? `display: none` : ``"
      >
        <button>SPIM</button>
        <button>Digitalization</button>
        <button>SaaS</button>
      </div>
    </div>
    <div class="content">
      <h1>
        {{ $t('SaasTitle') }}
      </h1>
      <p>
        {{ $t('SaasIntro') }}
      </p>
      <h2>{{ $t('WhatIsSaaS?') }}</h2>
      <p id="sub">
        {{ $t('DefinitionSaaS') }}
      </p>
      <p>
        {{ $t('PointFirst') }}
      </p>
      <h2>{{ $t('History') }}</h2>
      <p>
        {{ $t('PointSecond') }}
      </p>
      <h2>{{ $t('KeyCharacteristics') }}</h2>
      <p id="sub">
        {{ $t('SubscriptionBased') }}
      </p>
      <p>
        {{ $t('PointThird') }}
      </p>
      <p id="sub">
        {{ $t('AccessibilitySaas') }}
      </p>
      <p>
        {{ $t('PointFourth') }}
      </p>
      <p id="sub">
        {{ $t('AutomaticUpdatesMaintenance') }}
      </p>
      <p>
        {{ $t('PointFifth') }}
      </p>
      <h2>{{ $t('Expanding') }}</h2>
      <img
        id="expand"
        src="../../assets/SaaS.svg"
        alt="SaaS Expanding"
      >
      <p>
        {{ $t('PointSixth') }}
      </p>
      <h2>{{ $t('Benefits') }}</h2>
      <p>
        {{ $t('Doubts') }}
      </p>
      <p id="sub">
        {{ $t('CostSavings') }}
      </p>
      <p>
        {{ $t('PointSeventh') }}
      </p>
      <p id="sub">
        {{ $t('AccessibilityCollaboration') }}
      </p>
      <p>
        {{ $t('PointEight') }}
      </p>
      <p id="sub">
        {{ $t('AutomaticUpdatesMaintenance') }}
      </p>
      <p>
        {{ $t('PointNine') }}
      </p>
      <p id="sub">
        {{ $t('DataSecurity') }}
      </p>
      <p>
        {{ $t('PointTen') }}
      </p>
      <h2>{{ $t('ChooseTheBest') }}</h2>
      <div class="tenant">
        <img
          src="../../assets/Tenants.svg"
          alt="SaaS platforms"
        >
        <p>
          {{ $t('PointEleven') }}
          <br><br>
          {{ $t('PointTwelve') }} <br><br>
          {{ $t('PointThirteen') }}
        </p>
      </div>
    </div>
    <!-- <div class="interaction">
      <div class="intImg">
        <img
          src="../../assets/AdrianaAuthor.svg"
          alt="author"
        >
      </div>
      <div class="enthusiast">
        <b>Adriana Rama</b>
        <p id="gray">
          Content Writer
        </p>
        <p id="quality">
          Digital content wrangler | UX enthusiast | Recovering educator | Shameless nerd & GIF connoisseur | Hockey fan | Oxford comma or death.
        </p>
      </div>
      <div>
        <img
          src="../../assets/linkedin-with-circle.svg"
          alt="linkedin"
        >
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
    computed: {
    ...mapGetters(["getLanguage"]),
    }
  }

</script>

<style scoped>
.blog{
  width: 73%;
  margin: auto;
  margin-bottom: 4%;
}

.blog img{
  margin-top: 4%;
}

.inspo{
  display: flex;
  justify-content: space-between;
  margin: 3% 0%;
  align-items: center;
}

.read{
  display: flex;
  align-items: center;
  width: 30%;
}

.authorname h6{
  font-size: 22px;
  font-weight: 500;
  color: #061C3D;
}

.authorname p{
  font-size: 20px;
  font-weight: 400;
  color: #42526B;
}

.authorname{
  margin-left: 2%;
}

.buttons{
  display: flex;
  gap: 3%;
}

.buttons button{
  background: #F3F7FF;
  width: 100px;
  height: 30px;
  border-radius: 6px;
  border: none;
  color: #161519;
  font-size: 16px;
  font-weight: 500;
  cursor: auto;
}

.content h1{
  font-size: 36px;
  font-weight: 700;
  color: #061C3D;
  margin-bottom: 2%;
}

.content p{
  font-size: 17px;
  font-weight: 300;
  color: #516371;
}

.content{
  margin-bottom: 5%;
  text-align: justify;
  text-justify: inter-word;
}

.interaction{
  display: flex;
  background: #F3F7FF;
  height: 188px;
  border-radius: 8px;
  align-items: center;
  padding: 3%;
  /* justify-content: space-evenly; */
}

.intImg{
  margin-right: 2%;
}

.enthusiast b{
  font-size: 16px;
  font-weight: 700;
  color: #111013;
}

#gray{
  font-size: 15.6px;
  font-weight: 400;
  color: #60606B;
}

.quality{
  font-size: 16.4px;
  font-weight: 400;
  color: #161519;
}

.author{
  width: 23%;
}

.intImg{
  width: 8%;
}

#sub {
  font-size: 22px;
}

#expand {
  width: 75%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 3%;
}

.tenant {
  display: flex;
}

.tenant img {
  width: 22%;
  margin-right: 3%;
  margin-bottom: 3%;
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .author{
    width: 13% !important;
  }

  .author img{
    width: 100%;
  }

  .inspo{
    display: flex;
    flex-direction: column;
    margin: 1% 0% !important;
  }

  .read{
    width: 100%;
    margin-bottom: 1% !important;
  }

  .buttons{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

@media screen and (max-width: 1000px){

  .blog{
  width: 85%;
  margin: auto;
  margin-bottom: 4%;
}
  .inspo{
    display: flex;
    flex-direction: column;
    margin: 5% 0%;
  }

  .author img{
    width: 100%;
  }

  .read{
    width: 100%;
    margin-bottom: 5%;
  }
  .author{
    width: 20%;
  }
  .authorname h6{
    font-size: 18px;
    margin-bottom: 0px;
  }

.authorname p{
  font-size: 15px;
  margin-bottom: 0px;
}

.content h1{
  font-size: 25px;
  margin: 5% 0%;
}
}

@media screen and (max-width: 600px) {
  .tenant {
  display: flex;
  flex-direction: column;
}

.tenant img {
  width: 80%;
  margin: auto;
  margin-bottom: 3%;
}
}</style>