<template>
  <div class="background">
    <video
      controls
      width="100%"
      poster="../../assets/videoprev.svg"
    >
      <source
        src="../../assets/video/DIGIT.mp4"
        type="video/mp4"
      >

    </video>
  </div>
</template>

<style scoped>
.background {
  width: 73%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}

.background video {
  width: 100%;
}
</style>