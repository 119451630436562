<template>
  <div class="background">
    <p id="trust">
      {{ $t('Partners') }}
    </p>
    <div class="logos">
      <a
        href="https://www.microsoft.com/"
        target="_blank"
      >
        <img
          src="../../assets/Microsoft.svg"
          alt="Microsoft"
        >
      </a>
      <a
        href="https://ickosovo.com/"
        target="_blank"
      >
        <img
          src="../../assets/ICK.svg"
          alt="ICK"
        >
      </a>
      <a
        href="https://aws.amazon.com/"
        target="_blank"
      >
        <img
          src="../../assets/AWS.svg"
          alt="AWS"
        >
      </a>
      <a
        href="https://www.cyres-consulting.com/"
        target="_blank"
      >
        <img
          id="cyres"
          src="../../assets/Cyres.svg"
          alt="Cyres"
        >
      </a>
      <a
        href="https://www.context.ag/"
        target="_blank"
      >
        <img
          id="context"
          src="../../assets/contextnew.svg"
          alt="Context"
        >
      </a>
      <a
        href="https://www.ds-logistik.ch/"
        target="_blank"
      >
        <img
          src="../../assets/dslogistik.svg"
          alt="DSLogistik"
        >
      </a>
    </div>
  </div>
</template>

<style scoped>
.background{
  background-image: url('../../assets/oval.svg');
  background-size: contain;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.logos{
  width: 73%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  gap: 7%;
}

.logos img{
  width: 100%;
  height: 100%;
}

.logos a {
  width: 25%;
  display: flex;
  justify-content: center;
  margin-block: 3%;
}

#trust{
  color: #A7ABB6;
  font-size: 19px;
  display: flex;
  justify-content: center;
}

#context {
  width: 85%;
  height: 80%;
}

@media screen and (min-width: 1000px) and (max-width: 1300px){
  .logos img{
    width: 100%;
  }

  .background{
  background-image: url('../../assets/oval.svg');
  background-size: contain;
  height: 600px;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
}

.logos a {
    width: 30%;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .logos{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logos img{
    width: 100%;
    margin-bottom: 8%;
  }

  .logos a {
    width: 40%;
    display: flex;
    justify-content: center;
    margin-block: 3%;
  }

  .background{
    margin-bottom: 10%;
  }

  #context {
    width: 80%;
    height: 80%;
  }
}

@media screen and (max-width: 600px){
  .logos{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logos img{
    width: 100%;
    margin-bottom: 8%;
  }

  .logos a {
    width: 65%;
    display: flex;
    justify-content: center;
    margin-block: 3%;
  }

  .background{
    background-image: url('../../assets/oval.svg');
    background-size: contain;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    margin-bottom: 60% !important;
    margin-top: 25%;
  }

  #context {
    width: 75%;
    height: 80%;
  }
}
</style>