<template>
  <div class="career">
    <div class="title">
      <div class="emoji">
        <h1>
          {{ $t('JoinATeam') }}
          <span style="color: #0041e8">
            {{ $t('InspiresYou') }} <img
              src="../../assets/sunemoji.svg"
              alt="emoji"
            ></span>
        </h1>
      </div>
      <p>
        {{ $t('CareerText') }}
      </p>
    </div>
    <div class="openings">
      <div
        class="card"
        :class="{ blurred: shouldApplyBlur }"
      >
        <div class="card-body">
          <div>
            <h5 class="card-title">
              {{ $t('FrontendDeveloper') }}
            </h5>
            <h6 class="card-subtitle mb-2 text-body-secondary">
              Intern
            </h6>
            <p class="card-text">
              {{ $t('FrontendDesc') }}
            </p>
          </div>
          <div>
            <div class="button">
              <button class="clock">
                <img
                  src="../../assets/clock.svg"
                  alt="clock"
                ><b>Full-Time</b>
              </button>
              <button class="clock">
                <img
                  src="../../assets/location.svg"
                  alt="location"
                ><b>On-Site</b>
              </button>
            </div>
            <button
              class="view"
              :disabled="shouldApplyBlur"
            >
              <a href="">{{ $t('ViewOpening') }}</a>
            </button>
          </div>
        </div>
      </div>

      <div
        class="card"
        :class="{ blurred: shouldApplyBlur }"
      >
        <div class="card-body">
          <div>
            <h5 class="card-title">
              {{ $t('BackendDeveloper') }}
            </h5>
            <h6 class="card-subtitle mb-2 text-body-secondary">
              Intern
            </h6>
            <p class="card-text">
              {{ $t('BackendDesc') }}
            </p>
          </div>
          <div>
            <div class="button">
              <button class="clock">
                <img
                  src="../../assets/clock.svg"
                  alt="clock"
                ><b>Full-Time</b>
              </button>
              <button class="clock">
                <img
                  src="../../assets/location.svg"
                  alt="location"
                ><b>On-Site</b>
              </button>
            </div>
            <button
              class="view"
              :disabled="shouldApplyBlur"
            >
              <a href="">{{ $t('ViewOpening') }}</a>
            </button>
          </div>
        </div>
      </div>

      <div
        class="card"
        :class="{ blurred: shouldApplyBlur }"
      >
        <div class="card-body">
          <div>
            <h5 class="card-title">
              {{ $t('CyberSecurityCareer') }}
            </h5>
            <h6 class="card-subtitle mb-2 text-body-secondary">
              Intern
            </h6>
            <p class="card-text">
              {{ $t('CyberSecurityDesc') }}
            </p>
          </div>
          <div>
            <div class="button">
              <button class="clock">
                <img
                  src="../../assets/clock.svg"
                  alt="clock"
                ><b>Full-Time</b>
              </button>
              <button class="clock">
                <img
                  src="../../assets/location.svg"
                  alt="location"
                ><b>On-Site</b>
              </button>
            </div>
            <button
              class="view"
              :disabled="shouldApplyBlur"
            >
              <a href="">{{ $t('ViewOpening') }}</a>
            </button>
          </div>
        </div>
      </div>
    </div>
    <p id="ready">
      {{ $t('ReadyTo') }} <br><span style="color: #0B63E5;"> {{ $t('DigIT?') }}</span>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shouldApplyBlur: true, // Replace false with your condition
    };
  },
};
</script>

<style scoped>

.blurred {
  filter: blur(2px);
}
.career {
  width: 73%;
  margin: auto;
  margin-bottom: 4%;
}

.title {
  text-align: center;
  margin: 8% 0%;
}

.title p {
  color: #061c3d;
  font-size: 18px;
  font-weight: 400;
}
.emoji {
  display: flex;
  text-align: center;
  justify-content: center;
}

.emoji img {
  width: 3%;
  height: 100%;
}

.emoji h1 {
  font-size: 55px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}

.openings {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: 26rem;
  height: 400px;
  padding: 1%;
  border: 1px solid #b3c5f5;
  margin-bottom: 3%;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-title {
  color: #061c3d;
  font-size: 24px;
  font-weight: 600;
}

.card-subtitle {
  color: #061c3d;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 2%;
}

.card-text {
  color: #516371;
  font-size: 16px;
  font-weight: 300;
}

.button {
  display: flex;
  gap: 2%;
  margin-bottom: 5%;
}

.clock {
  background: #f3f7ff;
  border: none;
  width: fit-content;
  height: 38px;
  border-radius: 6px;
  cursor: default;
}

.clock img {
  width: 20%;
  height: 100%;
}

.view {
  width: 100%;
  height: 52px;
  border-radius: 6px;
  background-color: #0041e8;
  border: none;
}

.view a {
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}
#ready{
  display: flex;
  justify-content: center;
  margin-top: 4%;
  font-size: 25px;
  font-weight: 600;
  gap: 0.3%;
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .card {
    width: 22rem;
    height: fit-content;
    padding: 1%;
    border: 1px solid #b3c5f5;
    margin-bottom: 3%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .card {
    width: 26rem;
    height: fit-content;
    padding: 1%;
    border: 1px solid #b3c5f5;
    margin-bottom: 3%;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .card {
    width: 25rem;
    height: fit-content;
    padding: 1%;
    border: 1px solid #b3c5f5;
    margin-bottom: 3%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .card {
    width: 22rem;
    height: fit-content;
    padding: 1%;
    border: 1px solid #b3c5f5;
    margin-bottom: 3%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .emoji h1 {
    font-size: 40px;
    width: 100%;
  }

  .emoji img {
    width: 5%;
  }

  .openings {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .career {
    width: 85%;
    margin: auto;
    margin-bottom: 4%;
  }
  .emoji h1 {
    font-size: 31px;
  }

  .emoji img {
    width: 8%;
  }
}
</style>
