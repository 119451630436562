<template>
  <div :dir="direction">
    <!-- Your component content here -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      direction: "ltr" // Default direction is left-to-right
    };
  },
  watch: {
    '$i18n.locale'(newVal) {
      // Set direction based on the selected language
      if (newVal === 'ar') {
        this.direction = 'rtl';
      } else {
        this.direction = 'ltr';
      }
    }
  }
};
</script>