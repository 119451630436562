<template>
  <div
    class="navbar"
  >
    <nav :style="getLanguage == 'ar' ? `display: flex; flex-direction: row-reverse` : `display: flex; flex-direction: row`">
      <div
        class="forcollection"
        :style="getLanguage == 'ar' ? `display: flex; flex-direction: row-reverse` : `display: flex; flex-direction: row`"
      >
        <Slide
          :close-on-navigation="true"
          class="burger-menu custom-burger-menu"
        >
          <router-link to="/">
            <span>{{ $t('Home') }}</span>
          </router-link>
          <div
            class="dropdown"
            @mouseover="showServicesDropdown"
            @mouseleave="hideServicesDropdown"
            @click.stop="toggleServicesDropdown"
          >
            <p class="services-link">
              {{ $t('Services') }} <img
                src="../../assets/drop.svg"
                alt=""
              >
            </p>
            <!-- Dropdown for Services in Burger menu -->
            <div
              v-show="isServicesDropdownVisible"
              class="dropdown-content"
            >
              <!-- Back button to go back to the main burger menu -->
              <button
                class="back"
                @click="goBack"
              >
                <img
                  src="../../assets/goback.svg"
                  alt="arrow"
                >
                {{ $t('Back') }}
              </button>
              <router-link to="/strategic-analysis">
                {{ $t('ITStrategicAnalysis') }}
              </router-link>
              <router-link to="/business-analysis">
                {{ $t('BusinessAnalysis') }}
              </router-link>
              <router-link to="/solution-engineering">
                {{ $t('SolutionsEngineering') }}
              </router-link>
              <router-link to="/uiux-graphic-design">
                {{ $t('GraphicDesign') }}
              </router-link>
              <router-link to="/data-management">
                {{ $t('DataManagement') }}
              </router-link>
              <router-link to="/cyber-security">
                {{ $t('CyberSecurity') }}
              </router-link>
            </div>
          </div>
          <router-link to="/aboutus">
            <span>{{ $t('About') }}</span>
          </router-link>
          <router-link to="/career">
            <span>{{ $t('Career') }}</span>
          </router-link>
          <router-link to="/blog">
            <span>{{ $t('Blog') }}</span>
          </router-link>
          <router-link to="/contact">
            <span>{{ $t('ContactUs') }}</span>
          </router-link>
          <a
            href="https://www.spim.app/"
            target="_blank"
          >
            <button id="burgerSpim">
              SPIM
              <img
                src="../../assets/pizza.svg"
                alt="pizza"
              >
            </button>
          </a>
          <div
            class="flags"
          >
            <span
              variant="dropLanguage"
              @click="changeL('en')"
            > <img
              src="../../assets/en.svg"
              alt="en"
            > </span>
            
            <span
              variant="dropLanguage"
              @click="changeL('de')"
            > <img
              src="../../assets/de.svg"
              alt="de"
            > </span>
            
            <span
              variant="dropLanguage"
              @click="changeL('ar')"
            > <img
              src="../../assets/ar.svg"
              alt="ar"
            > </span>
          </div>
        </Slide>
        <div class="logo">
          <a href="/">
            <img
              src="../../assets/Logo.svg"
              alt="logo"
            >
          </a>
        </div>
        <div
          class="nav"
          :style="getLanguage == 'ar' ? `display: flex; flex-direction: row-reverse` : `display: flex; flex-direction: row`"
        >
          <router-link
            to="/"
            :class="{ 'active': $route.path === '/' }"
          >
            <p>{{ $t('Home') }}</p>
          </router-link>

          <!-- Dropdown for Services -->
          <div
            class="dropdown"
            @mouseover="showDropdown"
            @mouseleave="hideDropdown"
          >
            <p
              class="services-link"
              :class="{ 'active': $route.path === '/strategic-analysis' || $route.path === '/business-analysis' || $route.path === '/solution-engineering' || $route.path === '/uiux-graphic-design' || $route.path === '/data-management' || $route.path === '/cyber-security' }"
            >
              {{ $t('Services') }} <img
                src="../../assets/drop.svg"
                alt=""
              >
            </p>
            <div
              v-show="isDropdownVisible"
              class="dropdown-content"
            >
              <router-link
                to="/strategic-analysis"
                :class="{ 'active-service': $route.path === '/strategic-analysis' }"
              >
                {{ $t('ITStrategicAnalysis') }}
              </router-link>
              <router-link
                to="/business-analysis"
                :class="{ 'active-service': $route.path === '/business-analysis' }"
              >
                {{ $t('BusinessAnalysis') }}
              </router-link>
              <router-link
                to="/solution-engineering"
                :class="{ 'active-service': $route.path === '/solution-engineering' }"
              >
                {{ $t('SolutionsEngineering') }}
              </router-link>
              <router-link
                to="/uiux-graphic-design"
                :class="{ 'active-service': $route.path === '/uiux-graphic-design' }"
              >
                {{ $t('GraphicDesign') }}
              </router-link>
              <router-link
                to="/data-management"
                :class="{ 'active-service': $route.path === '/data-management' }"
              >
                {{ $t('DataManagement') }}
              </router-link>
              <router-link
                to="/cyber-security"
                :class="{ 'active-service': $route.path === '/cyber-security' }"
              >
                {{ $t('CyberSecurity') }}
              </router-link>
            </div>
          </div>

          <router-link
            to="/aboutus"
            :class="{ 'active': $route.path === '/aboutus' }"
          >
            <p>{{ $t('About') }}</p>
          </router-link>

          <router-link
            to="/career"
            :class="{ 'active': $route.path === '/career' }"
          >
            <p>{{ $t('Career') }}</p>
          </router-link>

          <router-link
            to="/blog"
            :class="{ 'active': $route.path === '/blog' || $route.path === '/from-inventory-mishaps-to-creative-triumphs' || $route.path === '/healthcare-digital-dilemma' || $route.path === '/slow-fashion' || $route.path === '/saas-platform' || $route.path === '/benefits-of-vuejs' || $route.path === '/website-redesign-checklist' }"
          >
            <p>{{ $t('Blog') }}</p>
          </router-link>
        </div>
        <div class="contact">
          <a
            href="https://www.spim.app/"
            target="_blank"
          >
            <button id="spim">
              SPIM
              <img
                src="../../assets/pizza.svg"
                alt="pizza"
              >
            </button>
          </a>
          <router-link to="/contact">
            <button id="contactUs">
              {{ $t('ContactUs') }}
            </button>
          </router-link>
        </div>
      </div>
      <div>
        <div class="language-container">
          <div
            class="dropdownLang"
            style="margin-left: 10px;"
            @mouseover="showLangDropdown"
            @mouseleave="hideLangDropdown"
          >
            <img
              src="../../assets/world.svg"
              alt="about"
            >
            <div
              v-show="isDropdownLangVisible"
              class="dropdownLang-content"
            >
              <span
                :text="language"
                variant="btnLanguage"
                class="dropdownLang-content"
              >
                <span
                  variant="dropLanguage"
                  @click="changeL('en')"
                > <img
                  src="../../assets/en.svg"
                  alt="en"
                > </span>
                <div>
                  <span
                    variant="dropLanguage"
                    @click="changeL('de')"
                  > <img
                    src="../../assets/de.svg"
                    alt="de"
                  > </span>
                </div>
                <div>
                  <span
                    variant="dropLanguage"
                    @click="changeL('ar')"
                  > <img
                    src="../../assets/ar.svg"
                    alt="ar"
                  > </span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { computed } from "vue";
import { mapGetters, mapActions } from "vuex";
import { Slide } from 'vue3-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
    components: {
        Slide
    },
    data() {
      return {
        isDropdownVisible: false, // for services dropdown
        isServicesDropdownVisible: false, //for burger menu
        isDropdownLangVisible: false, //for language selector
      };
    },
    computed: {
    ...mapGetters(["getLanguage"]),
    language() {
      if (this.getLanguage === "en") {
        return "English";
      } else if (this.getLanguage === "ar") {
        return "Arabic";
      }
      return "German";
    },
  },
  mounted() {
    this.changeL(this.getLanguage);
  },
    methods: {
      ...mapActions(['changeLanguage']),
        showDropdown() {
          this.isDropdownVisible = true;
        },
        hideDropdown() {
          this.isDropdownVisible = false;
        },
        showServicesDropdown() {
        this.isServicesDropdownVisible = true;
        },
        hideServicesDropdown() {
          this.isServicesDropdownVisible = false;
        },
        goBack() {
        this.isServicesDropdownVisible = false;
      },
      changeL(lang) {
        this.changeLanguage(lang);
        if (this.$i18n.locale !== lang) {
          this.$i18n.locale = lang;
          // Set direction to RTL for Arabic language
          if (lang === "ar") {
            document.documentElement.dir = "rtl";
          } else {
            // For other languages, set direction to LTR
            document.documentElement.dir = "ltr";
          }
        }
      },
      showLangDropdown() {
      this.isDropdownLangVisible = true;
    },
    hideLangDropdown() {
      this.isDropdownLangVisible = false;
    },
    },
};
</script>

<style scoped>

.burger-menu {
  display: none;
}

.nav a.active p {
  color: #0041E8;
  font-weight: 700;
}

.dropdown p.active{
  color: #0041E8;
  font-weight: 700;
}

.dropdown-content .active-service {
  color: #0041E8;
}

.dropdown {
  position: relative;
}

.forcollection {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.services-link {
  cursor: pointer;
  color: #838E9E;
  size: 17px;
  font-weight: 500;
  line-height: 25.45px;
  margin-bottom: 0px;
}

.services-link img{
  width: 10px;
  height: 100%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 230px;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-content a {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #E5E5E5;
  text-align: center;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.navbar{
  width: 100%;
}

nav{
  width: 73%;
  margin: auto;
  display: flex;
  -webkit-box-shadow: 2px 2px 22px 7px rgba(245,245,245,0.84);
  -moz-box-shadow: 2px 2px 22px 7px rgba(245,245,245,0.84);
  box-shadow: 2px 2px 22px 7px rgba(245,245,245,0.84);
  padding: 15px;
  align-items: center;
  border-radius: 21.21px;
  height: 94px;
  margin-top: 1%;
}

.nav{
  display: flex;
  gap: 10%;
  width: 46%;
  align-items: center;
  justify-content: center;
}

.nav a p{
  color: #838E9E;
  size: 17px;
  font-weight: 500;
  line-height: 25.45px;
  margin-bottom: 0px;
}

.nav a{
  text-decoration: none;
}
.contact{
  display: flex;
  gap: 5%;
  width: 22%;
}

.contact #spim{
  border: 2px solid #0041E8;
  background: white;
  border-radius: 5px;
  width: 127px;
  height: 50px;
  color: #0041E8;
  font-size: 16px;
  font-weight: 600;
}

.logo img{
  margin-bottom: 0px;
  padding-left: 3%;
  width: 100%;
  height: 100%;
}

.contact #contactUs{
  background: #0041E8;
  border-radius: 5px;
  width: 127px;
  height: 50px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

#spim img{
  width: 20px;
  height: 100%;
}

#spim{
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact a {
  text-decoration: none;
}

/*for the language selector dropdown*/


.dropdownLang{ 
  width: 2%;
  height: 100%;
}

.language-container {
  position: relative;
}

.dropdownLang {
  position: relative;
}

.dropdownLang-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  width: 90px;
  top: calc(100% + 0px); /* Position dropdown below the language selector */
  left: 50%;
  transform: translateX(-50%);
}

.dropdownLang-content span {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  cursor: pointer;
}

.dropdownLang-content span img {
  width: 100%;
}

.dropdownLang-content a:hover {
  background-color: #f1f1f1;
}

.dropdownLang:hover .dropdownLang-content {
  display: block;
}

@media screen and (min-width: 1785px) and (max-width: 1840px){
  .nav{
    width: 55%;
  }
}

@media screen and (min-width: 1700px) and (max-width: 1785px){
  .nav{
    width: 50%;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1700px){
  .nav{
    width: 55%;
    gap: 7%;
  }

  .contact #spim, .contact #contactUs {
    width: 115px;
  }

  .contact {
    width: 26%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1360px){
  .contact{
    width: 30%;
  }
  .nav{
    width: 60%;
  }

  nav{
  width: 85%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px){
  nav{
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  -webkit-box-shadow: 2px 2px 22px 7px rgba(245,245,245,0.84);
  -moz-box-shadow: 2px 2px 22px 7px rgba(245,245,245,0.84);
  box-shadow: 2px 2px 22px 7px rgba(245,245,245,0.84);
  padding: 15px;
  align-items: center;
  border-radius: 21.21px;
  height: 94px;
  margin-top: 1%;
}

.contact {
  width: 27%;
}

.contact #spim, .contact #contactUs {
  width: 115px;
}
}

@media screen and (max-width: 1000px){
  .burger-menu {
    display: block;
  }

  nav{
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    width: 30%;
    height: 80px;
    padding: 5px;
  }

  .logo{
    padding-left: 3%;
  }

  .nav{
    display: none !important;
  }

  .contact button, .contact img, #spim{
    display: none;
  }

    #burgerSpim{
    border: 1px solid #0041E8 ;
    background: white;
    border-radius: 5px;
    width: 127px;
    height: 50px;
    color: #0041E8;
    font-size: 16px;
    font-weight: 600;
  }

  #burgerSpim img{
    width: 20px;
  }

  #burgerSpim{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bm-menu{
    left: 20px;
    top: 0px;
  }


  .navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .services-link{
    color: white;
    margin-left: 8px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #3f3f41;
    box-shadow: none;
    z-index: 1;
    width: 290px;
    left: 43%;
    top: -66px;
    transform: translateX(-50%);
    height: 700px;
  }

  .dropdown-content a {
    color: white;
    padding: 10px 16px;
    text-decoration: none;
    display: block;
    font-size: 17px;
    font-weight: 500;
    border: none;
    text-align: left;
  }

  .dropdown-content a:hover {
  background-color: #7f7e7e;
}

.back{
  background: none;
  color: white;
  border: none;
  margin: 3% 0%;
  font-size: 17px;
}

.back img {
  width: 7px;
}

/*for the language selector dropdown*/

.dropdownLang{ 
  display: none;
}

.dropdownLang-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* z-index: 1; */
  width: 90px;
  left: 84.5%;
  top: 44px;
  transform: translateX(-50%);
}

.dropdownLang-content span {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  cursor: pointer;
}

.dropdownLang-content span img {
  width: 100%;
}

.dropdownLang-content a:hover {
  background-color: #f1f1f1;
}

.dropdownLang:hover .dropdownLang-content {
  display: block;
}

.flags {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flags img {
  width: 50%;
}
}
</style>