<template>
  <div>
    <NavBar />
    <router-view />
    <FooterDigit />
  </div>
</template>

<script>
import NavBar from './components/NavBar/NavBar.vue'
import FooterDigit from './components/Footer/FooterDigit.vue'
import store from "./store";

export default {
  components: {
    NavBar,
    FooterDigit
  },
  beforeCreate() {
    var requestOptions = {
      method: "GET",
    };

    fetch(
      "https://api.geoapify.com/v1/ipinfo?&apiKey=1004a217a95a4bfc959852dfc217fd69",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result.country.iso_code);
        if (result.country.iso_code == "XK") {
          store.commit("SET_LANGUAGE", "en");
        } else if (result.country.iso_code == "AE" || result.country.iso_code == "QAT" || result.country.iso_code == "SAU" || result.country.iso_code == "BHR" || result.country.iso_code == "YEM" || result.country.iso_code == "OMN") {
          store.commit("SET_LANGUAGE", "ar");
        } else if (result.country.iso_code == "CH" || result.country.iso_code == "DE" || result.country.iso_code == "AT" ) {
          store.commit("SET_LANGUAGE", "de");
        } else {
          store.commit("SET_LANGUAGE", "en");
        }
      })
      .catch((error) => console.log("error", error));
  },
}
</script>

<style>
@font-face {
  font-family: "Lato";
  src: local("Inter"),
    url(../src/assets/fonts/Lato-Regular.ttf) format("truetype");
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
