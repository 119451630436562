<template>
  <div class="clients">
    <div
      class="klasa"
    >
      <div class="say">
        <h1>
          {{ $t('ClientWords') }}
        </h1>
      </div>
      <div
        v-if="shouldShowSlider"
        class="slideri"
      >
        <Splide
          :options="options"
          aria-label="My Favorite Images"
        >
          <SplideSlide>
            <div class="boxat">
              <p>{{ $t('FirstFeedback') }}</p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div class="boxat">
              <p>
                {{ $t('SecondFeedback') }}
              </p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div class="boxat">
              <p>{{ $t('ThirdFeedback') }}</p>
            </div>
          </SplideSlide>
        </Splide>
      </div>
      <div class="slideri1">
        <Splide
          :options="options"
          aria-label="My Favorite Images"
        >
          <SplideSlide>
            <div class="boxat">
              <p>{{ $t('FirstFeedback') }}</p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div class="boxat">
              <p>
                {{ $t('SecondFeedback') }}
              </p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div class="boxat">
              <p>{{ $t('ThirdFeedback') }}</p>
            </div>
          </SplideSlide>
        </Splide>
      </div>
      <div class="quotes">
        <img
          src="../../assets/Quotes.svg"
          alt="quotes"
        >
      </div>
    </div>

    <div class="clientImg">
      <img
        src="../../assets/clients.svg"
        alt="clients"
      >
    </div>
  </div>
</template>

<script>
import '@splidejs/vue-splide/css';

// or other themes
import '@splidejs/vue-splide/css/skyblue';
import '@splidejs/vue-splide/css/sea-green';

// or only core styles
import '@splidejs/vue-splide/css/core';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

export default ( {
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return{
      shouldShowSlider: true,
      options: {
        type   : 'fade',
            pagination:true,
            noDrag:true,
            rewind: true,
            autoplay: true,
            arrows:false,
            infinite: true,
            interval: 5500,
            perPage: 1,
            perMove: 1,
            dots:false,
            breakpoints: {
              1650: {
                
            perPage: 1,
            arrows:false,
          },
              1240: {
            perPage: 1,
            arrows:false,
            autoplay: true,
          },
              800: {
                type   : 'fade',
            perPage: 1,
            perMove: 1,
            arrows:false,
            autoplay: true,
          },
          500: {
            type   : 'fade',
            autoplay: true,
            perPage: 1,
            arrows:false,
          },
        },
          
      },
      options2: {
        type   : 'fade',
            pagination:false,
            noDrag:true,
            rewind: true,
            autoplay: true,
            arrows:false,
            infinite: true,
            interval: 3000,
            perPage: 1,
            perMove: 1,
            dots:false,
            breakpoints: {
              1650: {
                
            perPage: 1,
            arrows:false,
          },
              1240: {
            perPage: 1,
            arrows:false,
            autoplay: true,
          },
              801: {
            perPage: 1,
            perMove: 1,
            arrows:false,
            autoplay: true,
          },
          500: {
            type   : 'fade',
            autoplay: true,
            perPage: 1,
            arrows:false,
          },
        },
          
      }
    }
  },
  mounted() {
    // Check initial screen width on component mount
    this.checkScreenWidth();

    // Add event listener to dynamically check screen width on resize
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeUnmount() {
    // Remove event listener on component destroy to prevent memory leaks
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      // Set the value of shouldShowSlider based on screen width
      this.shouldShowSlider = window.innerWidth <= 501;
    }
  },
} );
</script>

<style scoped>
.clients{
  width: 73%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8%;
}

.klasa{
  width: 50%;
}

.boxat p{
  color: #0041E8;
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 5%;
}

.splide{
  padding: 0px 4px;
}

.say h1{
  font-size: 54px;
  color: #061C3D;
  font-weight: 700;
  margin-bottom: 4%;
}

.clientImg img{
  width: 100%;
  height: 100%;
}

.clientImg{
  width: 50%;
}

.boxat{
  width: 100%;
}

.quotes img {
  width: 8%;
  height: 100%;
}

@media screen and (min-width: 1000px) and (max-width: 1400px){
  .say h1{
    font-size: 40px;
  }
  .klasa{
    width: 50%;
  }

.boxat{
  width: 100%;
}

.boxat p{
  width: 100%;
}
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .say h1{
    font-size: 30px;
  }
  .clients{
    width: 85%;
    display: flex;
    /* flex-direction: column; */
  }
}

@media screen and (max-width: 600px){

  .clients{
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .clientImg {
    display: none;
  }
  .say h1{
    font-size: 22px;
  }

  .klasa{
    width: 100%;
  }

  .slideri1{
    display: none;
  }

  .boxat p{
    font-size: 18px;
  }
}

</style>