import { createApp } from "vue";
import VueSplide from "@splidejs/vue-splide";
import AOS from "aos";
import { createI18n } from "vue-i18n";
import en from "./i18n/en.json";
import de from "./i18n/de.json";
import ar from "./i18n/ar.json"
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCarousel from 'vue-carousel';
import { VueMarqueeSlider } from 'vue3-marquee-slider';
import '../node_modules/vue3-marquee-slider/dist/style.css'
// Vue.use(VueCarousel);


import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

const app = createApp(App);

import LanguageDirectionSetter from './components/LanguageDirectionSetter.vue';
app.component('LanguageDirectionSetter', LanguageDirectionSetter);

const i18n = createI18n({
  locale: store.state.language.lang,
  messages: {
    en,
    de,
    ar,
  },
});
store.watch(
  (state) => state.language.lang,
  (value) => {
    i18n.global.locale = value;
  }
);

app.use(i18n);
app.use(store);
app.use(router);
app.use(VueSplide);


app.mount("#app");

// Initialize AOS (if necessary)
AOS.init();
