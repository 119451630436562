import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import language from "./modules/language";


export default createStore({
  plugins: [
    createPersistedState({
      paths: ["language"],
      storage: window.localStorage,
    }),
  ],
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    language,
  },
});
