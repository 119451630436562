<template>
  <div>
    <FashionBlogContent />
    <LatestBlogInPageSF />
  </div>
</template>

<script>
import FashionBlogContent from '../components/slowfashionblog/FashionBlogContent.vue'
import LatestBlogInPageSF from '../components/slowfashionblog/LatestBlogInPageSF.vue'

export default {
  components: {
    FashionBlogContent,
    LatestBlogInPageSF,
  }
}
</script>