<template>
  <div class="blog">
    <img
      src="../../assets/redesignblogin.svg"
      alt="blog image"
    >
    <div class="inspo">
      <div class="read">
        <div class="author">
          <img
            src="../../assets/AdrianaAuthor.svg"
            alt="author"
          >
        </div>
        <div class="authorname">
          <h6>Adriana Rama</h6>
          <p>February 23, 2024</p>
        </div>
      </div>
      <div
        class="buttons"
        :style="getLanguage == 'ar' ? `display: none` : ``"
      >
        <button>{{ $t('Website') }}</button>
        <button>{{ $t('Design') }}</button>
        <button>{{ $t('Research') }}</button>
      </div>
    </div>
    <div class="content">
      <h1>{{ $t('RedesignTitle') }}​</h1>
      <p>
        {{ $t('IntroWebsiteRedesign') }} <br><br>
      </p>
      <p class="bold">
        1. {{ $t('DefineGoals') }}
      </p>
      <p>
        {{ $t('BeforeDiving') }}
      </p><br>
      <p class="bold">
        2. {{ $t('WebsiteAudit') }}
      </p>
      <p>
        {{ $t('EvaluateStrengths') }}
      </p><br>
      <p class="bold">
        3. {{ $t('ResearchAnalysis') }}
      </p>
      <p>
        {{ $t('GatherInsights') }}
      </p><br>
      <p class="bold">
        4. {{ $t('DevelopBudgetTimeline') }}
      </p>
      <p>
        {{ $t('DetermineBudget') }}
      </p><br>
      <p class="bold">
        5. {{ $t('CreateDesignMockups') }}
      </p>
      <p>
        {{ $t('VisualizeStructure') }}
      </p><br>
      <p class="bold">
        6. {{ $t('ContentStrategy') }}
      </p>
      <p>
        {{ $t('ComprehensiveContent') }}
      </p><br>
      <p class="bold">
        7. {{ $t('MobileResponsiveness') }}
      </p>
      <p>
        {{ $t('InTodaysWorld') }}
      </p><br>
      <p class="bold">
        8. {{ $t('SeoOptimization') }}
      </p>
      <p>
        {{ $t('DriveOrganicTraffic') }}
      </p><br>
      <p class="bold">
        9. {{ $t('AccessibilityCompliance') }}
      </p>
      <p>
        {{ $t('Wcag') }}
      </p><br>
      <p class="bold">
        10. {{ $t('TestingQA') }}
      </p>
      <p>
        {{ $t('ThroughoutQA') }}
      </p><br>
      <p class="bold">
        11. {{ $t('LaunchPlan') }}
      </p>
      <p>
        {{ $t('Coordinate') }}
      </p><br>
      <p class="bold">
        12. {{ $t('PostLaunch') }}
      </p>
      <p>
        {{ $t('KeyMetrics') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
    computed: {
    ...mapGetters(["getLanguage"]),
    }
  }

</script>

<style scoped>
.blog {
  width: 73%;
  margin: auto;
  margin-bottom: 4%;
}

.blog img {
  margin-top: 4%;
  width: 100%;
}

.bold {
  margin-bottom: 5px !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}

.inspo {
  display: flex;
  justify-content: space-between;
  margin: 3% 0%;
  align-items: center;
}

.read {
  display: flex;
  align-items: center;
  width: 30%;
}

.authorname h6 {
  font-size: 22px;
  font-weight: 500;
  color: #061c3d;
}

.authorname p {
  font-size: 20px;
  font-weight: 400;
  color: #42526b;
}

.authorname {
  margin-left: 2%;
}

.buttons {
  display: flex;
  gap: 3%;
}

.buttons button {
  background: #f3f7ff;
  width: 100px;
  height: 30px;
  border-radius: 6px;
  border: none;
  color: #161519;
  font-size: 16px;
  font-weight: 500;
  cursor: auto;
}

.content h1 {
  font-size: 36px;
  font-weight: 700;
  color: #061c3d;
  margin-bottom: 2%;
}

.content p {
  font-size: 17px;
  font-weight: 300;
  color: #516371;
  margin-bottom: 0px;
}

.content {
  margin-bottom: 5%;
  text-align: justify;
  text-justify: inter-word;
}

.interaction {
  display: flex;
  background: #f3f7ff;
  height: 188px;
  border-radius: 8px;
  align-items: center;
  padding: 3%;
  /* justify-content: space-evenly; */
}

.intImg {
  margin-right: 2%;
}

.enthusiast b {
  font-size: 16px;
  font-weight: 700;
  color: #111013;
}

#gray {
  font-size: 15.6px;
  font-weight: 400;
  color: #60606b;
}

.quality {
  font-size: 16.4px;
  font-weight: 400;
  color: #161519;
}

.author {
  width: 23%;
}

.intImg {
  width: 8%;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .author {
    width: 13% !important;
  }

  .author img {
    width: 100%;
  }

  .inspo {
    display: flex;
    flex-direction: column;
    margin: 1% 0% !important;
  }

  .read {
    width: 100%;
    margin-bottom: 1% !important;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .blog {
    width: 85%;
    margin: auto;
    margin-bottom: 4%;
  }
  .inspo {
    display: flex;
    flex-direction: column;
    margin: 5% 0%;
  }

  .author img {
    width: 100%;
  }

  .read {
    width: 100%;
    margin-bottom: 5%;
  }
  .author {
    width: 20%;
  }
  .authorname h6 {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .authorname p {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .content h1 {
    font-size: 25px;
    margin: 5% 0%;
  }
}
</style>
