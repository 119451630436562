<template>
  <div class="error">
    <img
      src="../../assets/404.svg"
      alt="404"
    >
    <h1>Oops! Something went wrong.</h1>
    <p>It's look like the link is broken or the page is removed.</p>
  </div>
  <!-- <div class="button">
    <button id="goback">
      <a href="/">Home</a>
    </button>
  </div> -->
</template>

<style scoped>
.error{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 7%;
  padding-bottom: 10%;
}

.error img{
  margin-bottom: 2%;
}

/* .button{
  display: flex; 
  justify-content:  center;
  padding-bottom: 10%;
} */

.button #goback{
  background: #0041E8;
  border-radius: 5px;
  width: 127px;
  height: 50px;
  border: none;
}

#goback a{
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.error{
  text-align: center;
}

.error h1{
  font-size: 56px;
  font-weight: 700;
  margin-bottom: 1%;
}

.error p{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 2%;
}

</style>