<template>
  <div class="contact">
    <div class="blue">
      <div>
        <h1>{{ $t('GetInTouch') }}</h1>
        <div class="email">
          <div>
            <img
              src="../../assets/Icons.svg"
              alt="icons"
            >
          </div>
          <div class="info">
            <p>{{ $t('EmailUs') }}</p>
            <a href="mailto: contacts@digitbusiness.ch"><p>contacts@digitbusiness.ch</p></a>
          </div>
        </div>

        <div class="phone">
          <div>
            <img
              src="../../assets/Icons1.svg"
              alt="icons"
            >
          </div>
          <div class="info">
            <p>{{ $t('PhoneNumber') }}</p>
            <a href="tel:+383 49 999 433"><p>+41 79 336 31 36</p></a>
          </div>
        </div>

        <div class="address">
          <div>
            <img
              src="../../assets/Icons2.svg"
              alt="icons"
            >
          </div>
          <div class="info">
            <p>{{ $t('Address') }}</p>
            <p>Steinstrasse 56, 8003 Zürich</p>
          </div>
        </div>
      </div>
      <div>
        <p>{{ $t('ConnectWithUs') }}</p>
        <div class="socials">
          <a
            href="https://www.facebook.com/DigITConsultingGmbH"
            target="_blank"
          >
            <img
              src="../../assets/fb.svg"
              alt="facebook"
            >
          </a>
          <a
            href="https://twitter.com/i/flow/login?redirect_after_login=%2FDigIT2122"
            target="_blank"
          >
            <img
              src="../../assets/X.svg"
              alt="twitter"
            >
          </a>
          <a
            href="https://ch.linkedin.com/company/digit-business-gmbh"
            target="_blank"
          >
            <img
              src="../../assets/linkedin2.svg"
              alt="linkedin"
            >
          </a>
          <a
            href="https://www.instagram.com/digitbusiness/"
            target="_blank"
          >
            <img
              src="../../assets/insta.svg"
              alt="instagram"
            >
          </a>
        </div>
      </div>
    </div>
    <div class="gray">
      <div class="buttons">
        <button
          id="individual"
          :class="{'individual': showDescription, 'business': !showDescription}"
        
          @click="showDescription = true"
        >
          {{ $t('Business') }}
        </button>
        <button
          id="business"
          :class="{'business': showDescription, 'individual': !showDescription}"

          @click="showDescription = false"
        >
          {{ $t('Individual') }}
        </button>
      </div>
      <div class="form">
        <div class="nameEmail">
          <div class="name">
            <label
              class=" label"
              for="full-name"
            >{{ $t('Name') }}</label>
            <input 
              id="full-name"
              v-model="firstName"
              type="text"
              :placeholder="$t('FullName')"
              required
            >
          </div>
          <div class="emailIn">
            <label
              class=" label"
              for="email"
            >{{ $t('Email') }}</label>
            <input
              id="email"
              v-model="email"
              type="text"
              :placeholder="$t('EmailAddress')"
              required
            >
          </div>
        </div>
        <label
          class=" label"
          for="subject"
        >{{ $t('Subject') }}</label>
        <input
          id="subject"
          v-model="subject"
          type="text"
          :placeholder="$t('Subject')"
          required
        >
        <label
          v-if="showDescription"
          class=" label"
          for="company"
        >{{ $t('CompanyName') }}</label>
        <input
          v-if="showDescription"
          id="company"
          v-model="company"
          type="text"
          :placeholder="$t('ProductName')"
          required
        >
        <label
          class=" label"
          for="message"
        >{{ $t('Message') }}</label>
        <textarea
          id="message"
          v-model="message"
          type="text"
          :placeholder="$t('TellUsProject')"
          required
        />
        <div class="submit">
          <button @click="submitForm">
            <img
              src="../../assets/arrow-rightW.svg"
              alt="arrow"
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AWSurl } from "@/domainConfig";
import AWS from "aws-sdk";

export default {
  data() {
    return {
      firstName: "",
      email: "",
      subject: "",
      company: "",
      message: "",
      showDescription: true
    }
  },
  methods: {
    async submitForm() {
      const ses = new AWS.SES({
        accessKeyId: AWSurl.accessKeyId,
        secretAccessKey: AWSurl.secretAccessKey,
        region: AWSurl.region,
      });

      const params = {
        Destination: {
          ToAddresses: ["contacts@digitbusiness.ch"],
        },
        Message: {
          Body: {
            Text: {
              Data: `FullName: ${this.firstName}
              Email: ${this.email}
              Subject: ${this.subject}
              Company: ${this.company}
              Message: ${this.message}`,
            },
          },
          Subject: {
            Data: "Form submission from DigIT website",
          },
        },
        Source: "contacts@digitbusiness.ch",
      };

      try {
        await ses.sendEmail(params).promise();
        console.log("Email sent successfully");
      } catch (err) {
        console.error(err);
      }
      this.resetForm();
      },
      resetForm() {
        (this.firstName = ""),
        (this.email = ""),
        (this.subject = ""),
        (this.company = ""),
        (this.message = "");
      },
    },
  };
</script>

<style scoped>

.contact{
  width: 73%;
  margin: auto;
  display: flex;
  margin-bottom: 4%;
}
.blue{
  background: #0041E8;
  width: 40%;
  padding: 4%;
  border-radius: 6px 0px 0px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blue img {
  width: 100%;
  height: 100%;
}

.blue h1, .blue p{
  color: white;
}

.blue h1{
  margin-bottom: 7%;
}

.email, .phone, .address{
  display: flex;
  gap: 2%;
  margin-bottom: 5%;
}

.socials{
  display: flex;
  gap: 2%;
}

.info p{
  margin-bottom: 0px;
  padding-top: 5px;
}

.info a{
  margin-bottom: 0px;
  text-decoration: none;
}

.info{
  display: flex;
  flex-direction: column;
}

.gray{
  background: #F3F7FF;
  width: 60%;
  padding: 4% 4% 2% 4%;
  border-radius: 0px 6px 6px 0px;
}

.individual{
  background: #0041E8;
  color: white;
  border: none;
  width: 228px;
  height: 52px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.business{
  background: white;
  color: #0041E8;
  border: 1px solid #0041E8;
  width: 228px;
  height: 52px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}


.buttons{
  display: flex;
  justify-content: center;
  gap: 4%;
  margin-bottom: 7%;
}

.form{
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.nameEmail{
  display: flex;
  justify-content: space-between;
}

.form input, .form textarea{
  border: 1px solid #E6E8EC;
  background: white;
  height: 48px;
  border-radius: 6px;
  margin-bottom: 3%;
  padding: 2%;
}

.form textarea{
  height: 100px;
  padding-top: 1%;
}

.name, .emailIn{
  display: flex;
  flex-direction: column;
  width: 48%;
}

.submit button{
  border: none;
  background:#0041E8;
  width: 50px;
  height: 50px;
  border-radius: 6px;
}

.submit{
  display: flex;
  justify-content: flex-end;
}

.submit img {
  width: 75%;
  height: 100%;
}

.form label{
  color: #061C3D;
  font-size: 16px;
}

.blue {
  background-image: url('../../assets/Shape.svg');
  background-repeat: no-repeat;
  background-size: 32%;
  background-position: top right;
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .contact{
    width: 100%;
    margin-bottom: 0%;
  }

  .blue, .gray{
    border-radius: 0%;
  }
}

@media screen and (max-width: 700px){
  .contact{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0%;
  }

  .blue{
    width: 100%;
    height: 600px;
    border-radius: 0%;
    padding: 15% 5% 15% 8%;
  }

  .gray{
    width: 100%;
    height: 600px;
    padding: 10% 4% 2% 4%;
    border-radius: 0%;
  }
}
</style>