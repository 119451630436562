<template>
  <div>
    <SolutionsEngineering />
    <IntroductionEngineering />
  </div>
</template>

<script>
import SolutionsEngineering from "@/components/solutionEngineering/SolutionsEngineering.vue"
import IntroductionEngineering from "@/components/solutionEngineering/IntroductionEngineering.vue"

export default {
  components: {
    SolutionsEngineering,
    IntroductionEngineering
  }
}
</script>