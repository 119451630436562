<template>
  <div>
    <PageNotFound />
  </div>
</template>

<script>
import PageNotFound from "@/components/notFound/PageNotFound.vue"

export default {
  components: {
    PageNotFound
  }
}
</script>