<template>
  <div>
    <DataManagement />
    <IntroductionData />
  </div>
</template>

<script>
import DataManagement from "@/components/dataManagement/DataManagement.vue"
import IntroductionData from "@/components/dataManagement/IntroductionData.vue"

export default {
  components: {
    DataManagement,
    IntroductionData
  }
}
</script>