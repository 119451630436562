<template>
  <div>
    <vuejsBlogContent />
    <latestBlogInVue />
  </div>
</template>

<script>
import vuejsBlogContent from "../components/vuejsBlog/vuejsBlogContent.vue"
import latestBlogInVue from "../components/vuejsBlog/latestBlogInVue.vue"

export default {
  components: {
    vuejsBlogContent,
    latestBlogInVue,
  }
}

</script>