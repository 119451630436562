<template>
  <div class="home">
    <div class="shine">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#f5d142"
        class="bi bi-stars"
        viewBox="0 0 16 16"
      >
        <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" />
      </svg>
      <p>
        {{ $t('WorkingwithUs') }}
      </p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="#f5d142"
        class="bi bi-stars"
        viewBox="0 0 16 16"
      >
        <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z" />
      </svg>
    </div>
    <div class="work">
      <div class="weekly">
        <img
          src="../../assets/weekly.svg"
          alt="Weekly Growth"
        >
      </div>
      <div class="maximaze">
        <h1>
          <span style="color: #0041E8;">{{ $t('Maximize') }}</span> {{ $t('YourOrganizational') }} <br>
          {{ $t('Efficiency') }} <span style="color: #0041E8;">{{ $t('yourworth') }}</span>.
        </h1>
      </div>
      <div class="trusted">
        <img
          src="../../assets/trusted.svg"
          alt="Clients"
        >
      </div>
    </div>
    <div class="call">
      <a href="/contact"><button id="call">{{ $t('ScheduleCall') }}<img
        src="../../assets/call-me-hand.svg"
        alt=""
      ></button></a>
    </div>
  </div>
</template>

<style>

.home{
  margin-top: 5%;
  margin-bottom: 5%;
}
.work{
  width: 73%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4%;
}

.maximaze h1{
  text-align: center;
  font-size: 54px;
  font-weight: 700;
  line-height: 62px;
}

.shine{
  display: flex;
  justify-content: center;
  align-items: center;
}

.shine p{
  color: #516371;
  font-size: 20px;
  margin-bottom: 0px;
}

.call{
  display: flex;
  justify-content: center;
  margin-bottom: 8%;
}

.call button{
  color: white;
  background-color: #0041E8;
  width: 210px;
  height: 55px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
}

#call img{
  width: 7%;
  height: 100%;
}

.call a{
  text-decoration: none;
}

.weekly img {
  width: 100%;
  height: 100%;
}

.trusted img {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1000px) and (max-width: 1200px){
  .work{
  width: 73%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .trusted, .weekly{
    display: none;
  }

  .maximaze h1{
    font-size: 43px;
    line-height: 55px;
    margin-bottom: 10%;
  }

  .call{
    margin-bottom: 15%;
  }
}

@media screen and (max-width: 600px){

  .home{
    margin-bottom: 20%;
  }

  .work{
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .trusted, .weekly{
    display: none;
  }

  .maximaze h1{
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 10%;
  }

  .design p{
    font-size: 18px;
  }

  .call button{
    margin-top: 15%;
  }
}
</style>