<template>
  <div>
    <StrategicAnalysis />
    <IntroductionStrategic />
  </div>
</template>

<script>
import StrategicAnalysis from "@/components/strategicAnalysis/StrategicAnalysis.vue"
import IntroductionStrategic from "@/components/strategicAnalysis/IntroductionStrategic.vue"

export default {
  components: {
    StrategicAnalysis,
    IntroductionStrategic
  }
}
</script>