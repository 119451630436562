<template>
  <div>
    <OurBlogs />
  </div>
</template>

<script>
import OurBlogs from "../components/BlogPage/OurBlogs.vue"

export default {
  components: {
    OurBlogs,
  }
}
</script>