<template>
  <div class="blog">
    <img
      src="../../assets/slowfashioninner.svg"
      alt="blog image"
    >
    <div class="inspo">
      <div class="read">
        <div class="author">
          <img
            src="../../assets/AdrianaAuthor.svg"
            alt="author"
          >
        </div>
        <div class="authorname">
          <h6>Adriana Rama</h6>
          <p>December 1, 2023</p>
        </div>
      </div>
      <div
        class="buttons"
        :style="getLanguage == 'ar' ? `display: none` : ``"
      >
        <button>SPIM</button>
        <button>Fashion</button>
        <button>Research</button>
      </div>
    </div>
    <div class="content">
      <h1>
        {{ $t('SlowFashionBlogTitle') }}
      </h1>
      <p>
        {{ $t('FastFashionIntro') }}
      </p>
      <h2>{{ $t('EnvironmentalImpact') }}</h2>
      <div class="water">
        <img
          src="../../assets/water.svg"
          alt="water"
        >
        <p>  
          {{ $t('FactOne') }} 
          <a
            href="https://webtv.un.org/en/asset/k1h/k1h7ss6tnz"
            target="_blank"
          >{{ $t('FirstLink') }}</a> 
          {{ $t('FactOneIn') }} 
          <a
            href="https://www.wri.org/insights/apparel-industrys-environmental-impact-6-graphics"
            target="_blank"
          >{{ $t('SecondLink') }}</a> 
          {{ $t('FactTwo') }} 
          <a
            href="https://www.europarl.europa.eu/news/en/headlines/society/20201208STO93327/the-impact-of-textile-production-and-waste-on-the-environment-infographics"
            target="_blank"
          >{{ $t('ThirdLink') }}</a> 
          {{ $t('FactTwoIn') }}
          <a
            href="https://earth.org/fast-fashions-detrimental-effect-on-the-environment/"
            target="_blank"
          >{{ $t('ForthLink') }}</a>
          {{ $t('FactThree') }} <a
            href="https://earth.org/"
            target="_blank"
          >Earth.org</a>
          {{ $t('FactThreeIn') }}
        </p>
      </div>
      <h2>{{ $t('SocialImpact') }}</h2>
      <p>
        {{ $t('FactFour') }}
        <a
          href="https://earth.org/sweatshops/#:~:text=In%20several%20manufacturing%20nations%2C%20including,India%20is%20barely%2058%20cents."
          target="_blank"
        >{{ $t('FifthLink') }}</a> 
        {{ $t('FactFourIn') }}
        <a
          href="https://www.ilo.org/wcmsp5/groups/public/---ed_norm/---ipec/documents/publication/wcms_800278.pdf"
          target="_blank"
        >{{ $t('SixthLink') }}</a> 
        {{ $t('FactFourInOne') }}
      </p>
      <img
        id="wages"
        src="../../assets/wages.svg"
        alt="wages"
      >
      <p style="display: flex; justify-content: center; margin: auto">
        {{ $t('PhotoSource') }}
      </p>
      <h2>{{ $t('WhySlowFashion') }} </h2>
      <p>
        {{ $t('FactFive') }}

        <br><br>{{ $t('FactFiveIn') }}
      </p>
      <h2>{{ $t('WhatCanIDo') }} </h2>
      <b> &#x2022; {{ $t('Mend') }}  </b>
      <p>{{ $t('FactSix') }} </p>
      <b> &#x2022; {{ $t('QualityOverQuantity') }}  </b>
      <p>{{ $t('FactSixInOne') }} </p>
      <b> &#x2022; {{ $t('MindfulWashing') }} </b>
      <p>{{ $t('FactSixInTwo') }} </p>
      <h2>{{ $t('WhatCanWeDo') }} </h2>
      <p>{{ $t('FactSeven') }} </p>
      <b> &#x2022; {{ $t('TakeBackPrograms') }} </b>
      <p>{{ $t('FactSevenIn') }} </p>
      <b> &#x2022; {{ $t('EcoFriendlyFabrics') }} </b>
      <p>{{ $t('FactSevenInOne') }} </p>
      <b> &#x2022; {{ $t('EcoFriendlyCollaborations') }} </b>
      <p>
        {{ $t('FactEight') }}

        <br><br>{{ $t('FactEightIn') }}
      </p>
    </div>
    <!-- <div class="interaction">
      <div class="intImg">
        <img
          src="../../assets/AdrianaAuthor.svg"
          alt="author"
        >
      </div>
      <div class="enthusiast">
        <b>Adriana Rama</b>
        <p id="gray">
          Content Writer
        </p>
        <p id="quality">
          Digital content wrangler | UX enthusiast | Recovering educator | Shameless nerd & GIF connoisseur | Hockey fan | Oxford comma or death.
        </p>
      </div>
      <div>
        <img
          src="../../assets/linkedin-with-circle.svg"
          alt="linkedin"
        >
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
    computed: {
    ...mapGetters(["getLanguage"]),
    }
  }

</script>

<style scoped>
.blog{
  width: 73%;
  margin: auto;
  margin-bottom: 4%;
}

.blog img{
  margin-top: 4%;
}

.inspo{
  display: flex;
  justify-content: space-between;
  margin: 3% 0%;
  align-items: center;
}

.read{
  display: flex;
  align-items: center;
  width: 30%;
}

.authorname h6{
  font-size: 22px;
  font-weight: 500;
  color: #061C3D;
}

.authorname p{
  font-size: 20px;
  font-weight: 400;
  color: #42526B;
}

.authorname{
  margin-left: 2%;
}

.buttons{
  display: flex;
  gap: 3%;
}

.buttons button{
  background: #F3F7FF;
  width: 100px;
  height: 30px;
  border-radius: 6px;
  border: none;
  color: #161519;
  font-size: 16px;
  font-weight: 500;
  cursor: auto;
}

.content h1{
  font-size: 36px;
  font-weight: 700;
  color: #061C3D;
  margin-bottom: 2%;
}

.content p{
  font-size: 17px;
  font-weight: 300;
  color: #516371;
}

.content b{
  font-weight: 500;
}

.content{
  margin-bottom: 5%;
  text-align: justify;
  text-justify: inter-word;
}

.interaction{
  display: flex;
  background: #F3F7FF;
  height: 188px;
  border-radius: 8px;
  align-items: center;
  padding: 3%;
  /* justify-content: space-evenly; */
}

.intImg{
  margin-right: 2%;
}

.enthusiast b{
  font-size: 16px;
  font-weight: 700;
  color: #111013;
}

#gray{
  font-size: 15.6px;
  font-weight: 400;
  color: #60606B;
}

.quality{
  font-size: 16.4px;
  font-weight: 400;
  color: #161519;
}

.author{
  width: 23%;
}

.intImg{
  width: 8%;
}

.water{
  display: flex;
}

.water img{
  width: 25%;
  margin-right: 3%;
}

#wages{
  width: 52%;
  display: flex;
  justify-content: center;
  margin: auto;
}

@media screen and (min-width: 600px) and (max-width: 1000px){
  .author{
    width: 13% !important;
  }

  .author img{
    width: 100%;
  }

  .inspo{
    display: flex;
    flex-direction: column;
    margin: 1% 0% !important;
  }

  .read{
    width: 100%;
    margin-bottom: 1% !important;
  }

  .buttons{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

@media screen and (max-width: 1000px){

  .blog{
  width: 85%;
  margin: auto;
  margin-bottom: 4%;
}
  .inspo{
    display: flex;
    flex-direction: column;
    margin: 5% 0%;
  }

  .author img{
    width: 100%;
  }

  .read{
    width: 100%;
    margin-bottom: 5%;
  }
  .author{
    width: 20%;
  }
  .authorname h6{
    font-size: 18px;
    margin-bottom: 0px;
  }

.authorname p{
  font-size: 15px;
  margin-bottom: 0px;
}

.content h1{
  font-size: 25px;
  margin: 5% 0%;
}
}

@media screen and (max-width: 600px){
  .water{
    display: flex;
    flex-direction: column;;
  }

.water img{
  width: 100%;
}

#wages{
  width: 100%;
}
}
</style>