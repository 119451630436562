<template>
  <div class="latestBlog">
    <div class="latest">
      <div class="title">
        <h1>{{ $t('LatestArticle') }}</h1>
        <a
          href="/blog"
          style="text-decoration: none;"
        >
          <button>
            <p>{{ $t('ReadOurBlog') }}</p><img
              src="../../assets/arrow-right.svg"
              alt="arrow"
            >
          </button>
        </a>
      </div>
      <div class="cras">
        <p>
          {{ $t('Symphony') }}
        </p>
      </div>
      <div class="blogs">
        <div
          class="card"
        >
          <a href="/website-redesign-checklist">
            <img
              src="../../assets/redesignblogout.svg"
              class="card-img-top"
              alt="blog picture"
            >
          </a>
          <div class="card-body">
            <a href="/website-redesign-checklist">
              <h5 class="card-title">
                {{ $t('RedesignTitle') }}
              </h5>
            </a>
            <div class="read">
              <div class="auth">
                <img
                  src="../../assets/AdrianaAuthor.svg"
                  alt="author"
                >
              </div>
              <div class="min">
                <h6>Adriana Rama</h6>
                <p>4 min read</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card"
        >
          <a href="/benefits-of-vuejs">
            <img
              src="../../assets/vueBlogOut.svg"
              class="card-img-top"
              alt="blog picture"
            >
          </a>
          <div class="card-body">
            <a href="/benefits-of-vuejs">
              <h5 class="card-title">
                {{ $t('TheBenefitsVue​') }}
              </h5>
            </a>
            <div class="read">
              <div class="auth">
                <img
                  src="../../assets/AdrianaAuthor.svg"
                  alt="author"
                >
              </div>
              <div class="min">
                <h6>Adriana Rama</h6>
                <p>7 min read</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card"
        >
          <a href="/saas-platform">
            <img
              src="../../assets/saasout.svg"
              class="card-img-top"
              alt="blog picture"
            >
          </a>
          <div class="card-body">
            <a href="/saas-platform">
              <h5 class="card-title">
                {{ $t('SaasTitle') }}
              </h5>
            </a>
            <div class="read">
              <div class="auth">
                <img
                  src="../../assets/AdrianaAuthor.svg"
                  alt="author"
                >
              </div>
              <div class="min">
                <h6>Adriana Rama</h6>
                <p>6 min read</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.latestBlog{
  background: #F3F7FF;
  margin-bottom: 4%;
  padding: 3% 0%;
}

.latest{
  width: 73%;
  margin: auto;
}

.blogs{
  display: flex;
  gap: 3%;
  flex-wrap: wrap;
  justify-content: center;
}
.title{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title img {
  width: 10%;
  height: 100%;
}

.title h1{
  font-size: 54px;
  font-weight: 600;
  margin-bottom: 2%;
  width: 100%;
  justify-content: space-between;
}

.card{
  border: none;
  border-radius: 6px;
  width: 22rem;
  margin-bottom: 3%;
}

.cras p{
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 3%;
  color: #42526B;
}

.title button{
  background: transparent;
  border: 1px solid #0B63E5;
  color: #0B63E5;
  font-size: 16px;
  font-weight: 700;
  width: 235px;
  height: 48px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title button p{
  margin-bottom: 0px;
  margin-right: 8px;
}

.card-title{
  font-size: 18px;
  margin-bottom: 7%;
}

.read{
  display: flex;
  gap: 3%;
}

.read h6{
  margin-bottom: 0px;
}

.auth{
  width: 15%;
}

.auth img {
  width: 100%;
  height: 100%;
}

.card-body{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.min p{
  margin-bottom: 0px;
}

.min{
  margin-top: 8px;
}

.card-img-top{
  width: 100%;
  height: 100%;
}

.card a {
  text-decoration: none;
  color: black;
}


@media screen and (min-width: 600px) and (max-width: 1000px){

  .latestBlog{
    margin-bottom: 0%;
  }
  .title{
    display: flex;
    flex-direction: column;
  }

  .title h1{
    font-size: 40px;
    margin-bottom: 10%;
  }

  .title button{
    display: none;
  }

  .cras p{
    display: none;
  }

  .latest{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .blogs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
  }

  .card{
    margin-bottom: 8%;
  }
}

@media screen and (max-width: 600px){

  .latest{
    width: 88%;
    margin: auto;
  }
  .title{
    display: flex;
    flex-direction: column;
  }

  .title h1{
    font-size: 30px;
  }

  .cras p{
    margin-bottom: 10%;
  }

  .title button{
    display: none;
  }

  .card{
    height: fit-content;
    margin-bottom: 10%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgb(198, 198, 198);
    border-radius: 0%;
    padding-bottom: 1%;
  }

  .read{
    display: flex;
    /* flex-direction: column; */
    width: 100%;
  }

  .card-body{
    display: flex;
    flex-direction: column;
    padding: 0%;
    width: 100%;
    padding-left: 4px;
  }

  .auth{
    width: 45%;
  }

  .min{
    width: 100%;
  }

  .latestBlog{
    margin-bottom: 0%;
    background: none;
  }

  .card-img-top{
    width: 100%;
    height: 95%;
  }

  .card-title{
    font-size: 16px;
  }


  .min h6{
    font-size: 14px;
  }

  .min p{
    font-size: 12px;
  }
}
</style>