<template>
  <div>
    <GraphicDesign />
    <IntroductionDigital />
  </div>
</template>

<script>
import GraphicDesign from "@/components/graphicDesign/GraphicDesign.vue"
import IntroductionDigital from "@/components/graphicDesign/IntroductionDigital.vue"

export default {
  components: {
    GraphicDesign,
    IntroductionDigital
  }
}
</script>